import motifHeader1 from "../motifs/motif-header-1.svg";
import motifHeader2 from "../motifs/motif-header-2.svg";
import motifHelix from "../motifs/motif-helix-blueS.svg";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @media (min-width: 25em) {
    flex-wrap: nowrap;
  }
  @media (min-width: 40em) {
    gap: 2rem;
  }
`;

const Back = styled(Link)`
  color: ${(props) => props.theme.color.white};
  display: inline-block;
  margin-bottom: 0.5rem;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.mint};
  }

  svg {
    fill: ${(props) => props.theme.color.mint};
    font-size: ${(props) => props.theme.size.down1};
    vertical-align: -0.1em;
  }
`;

interface ContainerProps {
  $hasMinimap?: boolean;
}

const Container = styled.header<ContainerProps>`
  align-items: center;
  background-color: ${(props) => props.theme.color.blueSS};
  background-image: url(${motifHelix}), url(${motifHelix});
  background-position: calc(100vw - 40rem) center, calc(100vw - 25rem) center;
  background-repeat: no-repeat;
  background-size: auto 30rem;
  color: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: start;
  min-height: 16rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  &::after,
  &::before {
    background-size: cover;
    content: "";
    display: none;
    position: absolute;
  }
  &::after {
    background-image: url(${motifHeader1});
    bottom: 0;
    height: 264px;
    left: -1rem;
    width: 144px;
  }
  &::before {
    background-image: url(${motifHeader2});
    bottom: -6.5rem;
    height: 278px;
    right: -2.5rem;
    width: 245px;
  }

  > * {
    position: relative;
    z-index: 10;
  }

  @media (min-width: 80em) {
    &::after,
    &::before {
      display: block;
    }
  }

  ${(props) =>
    props.$hasMinimap &&
    css`
      padding-bottom: 4rem;
    `}
`;

const enterContent = keyframes`
  from {
    opacity: 0;
    transform: translateY(0.25rem);
  }
`;

const Content = styled.div`
  animation: ${enterContent} 1000ms ${(props) => props.theme.ease.quintOut};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  @media (min-width: 40em) {
    gap: 2rem;
  }
  @media (min-width: 50em) {
    align-items: end;
    flex-direction: row;
  }
`;

const Header = { Actions, Back, Container, Content };

export default Header;
