import { Annotation } from "../../../app/redux/features/annotations";
import { ScaleLinear } from "d3";
import { FC } from "react";

interface MinimapAnnotationSignature {
  annotation: Annotation;
  layer: number;
  location: "above" | "below";
  minimapAnnotationHeight: number;
  minimapAnnotationPadding: number;
  minimapBackgroundHeight: number;
  minimapChartHeight: number;
  minimapX: ScaleLinear<number, number, never>;
}

export const MinimapAnnotation: FC<MinimapAnnotationSignature> = ({
  annotation,
  layer,
  location,
  minimapAnnotationHeight,
  minimapAnnotationPadding,
  minimapBackgroundHeight,
  minimapChartHeight,
  minimapX,
}) => {
  const modifier = location === "below" ? 1 : -1;
  const x = minimapX(annotation.start.x);
  const width = minimapX(annotation.end.x) + 1 - x;
  const y =
    minimapChartHeight / 2 -
    minimapAnnotationHeight / 2 -
    (-minimapBackgroundHeight -
      1 -
      (minimapAnnotationHeight + minimapAnnotationPadding) * (layer - 1)) *
      modifier;

  return (
    <rect
      className={`minimap-annotation minimap-annotation--${location}`}
      height={minimapAnnotationHeight}
      width={width}
      x={x}
      y={y}
    ></rect>
  );
};
