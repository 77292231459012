import { Annotation } from "../app/redux/features/annotations";
import { PriorityQueue } from "./priority-queue";

export interface LayeredAnnotation {
  annotation: Annotation;
  layer: number;
}

class Layer {
  annotations: Annotation[];

  constructor(readonly number: number, annotation: Annotation) {
    this.annotations = [annotation];
  }

  addAnnotation(annotation: Annotation): void {
    this.annotations.push(annotation);
  }

  get availableAfter(): number {
    return this.annotations[this.annotations.length - 1].end.x;
  }
}

const prioritizeFirstAvailable = (a: Layer, b: Layer): number => {
  return a.availableAfter - b.availableAfter;
};

const sortAscendingByStart = (
  { start: { x: a } }: Annotation,
  { start: { x: b } }: Annotation
): number => a - b;

const sortAscendingByNumber = ({ number: a }: Layer, { number: b }: Layer) =>
  a - b;

/**
 * This will take a collection of Annotations and determine the minimal number
 * of layers the arrangement of Annotations within them to avoid overlapping
 * intervals.
 *
 * This is an implementation of a greedy interval partitioning algorithm with a
 * modification to prefer using the lowest layer available.
 */
export function layerAnnotations(
  annotations: Annotation[]
): LayeredAnnotation[] {
  if (annotations.length === 0) {
    return [];
  }

  const layerQueue = new PriorityQueue<Layer>(prioritizeFirstAvailable);
  const sortedAnnotations = [...annotations].sort(sortAscendingByStart);
  const firstLayer = new Layer(1, sortedAnnotations.shift()!);
  layerQueue.add(firstLayer);

  sortedAnnotations.forEach((annotation) => {
    const availableLayers = layerQueue
      .toArray()
      .filter((layer) => layer.availableAfter < annotation.start.x)
      .sort(sortAscendingByNumber);

    if (availableLayers.length === 0) {
      const newLayer = new Layer(layerQueue.length + 1, annotation);
      layerQueue.add(newLayer);
      return;
    }

    availableLayers[0].addAnnotation(annotation);
    layerQueue.resort();
  });

  return layerQueue
    .toArray()
    .flatMap((layer) =>
      layer.annotations.map((annotation) => ({
        annotation,
        layer: layer.number,
      }))
    )
    .sort(
      (
        {
          annotation: {
            start: { x: a },
          },
        },
        {
          annotation: {
            start: { x: b },
          },
        }
      ) => a - b
    );
}
