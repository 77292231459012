import {
  Annotation,
  DataInstance,
  Project,
  SuggestedAnnotation,
  isManualAnnotation,
  isSuggestedAnnotation,
  isUneditedDataInstance,
} from "./types";

type Data = {
  annotations: Annotation[];
  dataInstances: DataInstance[];
  projects: Project[];
};

export class Store {
  private _data: Data;

  constructor(
    annotations: Annotation[] = [],
    dataInstances: DataInstance[] = [],
    projects: Project[] = []
  ) {
    this._data = { annotations, dataInstances, projects };
  }

  clear(): void {
    this.setData({ annotations: [], dataInstances: [], projects: [] });
  }

  createAnnotation(annotation: Annotation): Annotation {
    this._data.annotations.push(annotation);
    return annotation;
  }

  createDataInstance(dataInstance: DataInstance): DataInstance {
    this._data.dataInstances.push(dataInstance);
    return dataInstance;
  }

  createProject(project: Project): Project {
    this._data.projects.push(project);
    return project;
  }

  deleteSuggestedAnnotation(annotationId: string): void {
    this._data.annotations = this._data.annotations.filter((annotation) => {
      if (!isSuggestedAnnotation(annotation)) {
        return true;
      }

      return annotation.id !== annotationId;
    });
  }

  exportProjectModel(projectId: string): void {
    this._data.projects = this._data.projects.map((project) => {
      if (project.id !== projectId) {
        return project;
      }

      return {
        ...project,
        model_export: { status: "processing" },
      };
    });
  }

  findAllSuggestedAnnotationsByDataInstanceId(
    dataInstanceId: string
  ): SuggestedAnnotation[] {
    return this._data.annotations
      .filter((annotation) => annotation.instanceId === dataInstanceId)
      .filter(isSuggestedAnnotation);
  }

  findAllAnnotations({
    dataInstanceId,
    projectId,
  }: {
    dataInstanceId?: string;
    projectId?: string;
  }): Annotation[] {
    let annotations = [...this._data.annotations];

    if (projectId) {
      annotations = annotations.filter(
        (annotation) => annotation.taskId === projectId
      );
    }

    if (dataInstanceId) {
      annotations = annotations.filter(
        (annotation) => annotation.instanceId === dataInstanceId
      );
    }

    return annotations;
  }

  findAllDataInstancesByProjectId(projectId: string): DataInstance[] {
    return this._data.dataInstances.filter(
      (dataInstance) => dataInstance.taskId === projectId
    );
  }

  findAllProjects(): Project[] {
    return this._data.projects;
  }

  findDataInstanceByProjectId(
    dataInstanceId: string,
    projectId: string
  ): DataInstance | undefined {
    return this.findAllDataInstancesByProjectId(projectId).find(
      (dataInstance) => dataInstance.id === dataInstanceId
    );
  }

  findNextUneditedDataInstanceByProjectId(
    projectId: string
  ): DataInstance | undefined {
    const dataInstances = this.findAllDataInstancesByProjectId(projectId);
    return dataInstances.find(isUneditedDataInstance);
  }

  findProjectById(id: string): Project | undefined {
    return this._data.projects.find((project) => project.id === id);
  }

  resetProject(id: string): void {
    this._data.dataInstances = this._data.dataInstances.map((dataInstance) => {
      if (dataInstance.taskId !== id) {
        return dataInstance;
      }

      return { ...dataInstance, status: "unedited" };
    });

    this._data.annotations = this._data.annotations.filter((annotation) => {
      return annotation.taskId !== id || !isManualAnnotation(annotation);
    });

    this._data.projects = this._data.projects.map((project) => {
      if (project.id !== id) {
        return project;
      }

      return {
        ...project,
        confidence: {
          ...project.confidence,
          value: 0,
        },
        progress: {
          ...project.progress,
          annotations_done: 0,
        },
      };
    });
  }

  setData(data: Data): void {
    this._data = data;
  }

  setDataInstanceAsEdited(dataInstanceId: string, projectId: string): void {
    this._data.dataInstances = this._data.dataInstances.map((dataInstance) => {
      if (
        dataInstance.taskId !== projectId ||
        dataInstance.id !== dataInstanceId
      ) {
        return dataInstance;
      }

      return { ...dataInstance, status: "edited" };
    });
  }

  updateSuggestedAnnotation(
    id: string,
    newAnnotation: SuggestedAnnotation
  ): void {
    const index = this._data.annotations.findIndex(
      (annotation) => isSuggestedAnnotation(annotation) && annotation.id === id
    );

    if (index < 0) {
      return;
    }

    this._data.annotations.splice(index, 1, newAnnotation);
  }
}

export const store = new Store();
