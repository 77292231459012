import { RESET_STATE_ACTION_TYPE } from "../../actions/resetState";
import { Project, State } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: State = [];

export function isExportProcessing(project: Project): boolean {
  return project.model_export?.status === "processing";
}

const projectsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
  },
  name: "projects",
  initialState,
  reducers: {
    synchronizeProject: (state, { payload }: PayloadAction<Project>) => {
      const index = state.findIndex((project) => project.id === payload.id);

      if (index === -1) {
        return [...state, payload];
      }

      return state.map((project) =>
        project.id === payload.id ? payload : project
      );
    },
    synchronizeProjects: (_state, action: PayloadAction<Project[]>) => {
      return action.payload;
    },
  },
});

export const { name: key, reducer } = projectsSlice;
export const { synchronizeProject, synchronizeProjects } =
  projectsSlice.actions;
