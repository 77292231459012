import { Project } from "../../../models/project";
import { key } from "./slice";
import { State } from "./types";
import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: { [key]: State }) => state[key];

export const selectAllProjects = createSelector(selectSelf, (state) =>
  state.map((project) => new Project(project))
);
export const selectProjectByID = createSelector(
  [selectSelf, (_state, id: string) => id],
  (state, id: string) => {
    const project = state.find((p) => p.id === id);
    return project ? new Project(project) : undefined;
  }
);
