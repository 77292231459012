import { assert } from "../../../../../utils/assertions";
import {
  Annotation,
  Coordinate,
  ManualAnnotation,
  SuggestedAnnotation,
  isManualAnnotation,
  isSuggestedImageAnnotation,
  isSuggestedSequenceAnnotation,
} from "../../../features/annotations";
import { Project } from "../../../features/projects";
import {
  Annotation as TympanaAnnotation,
  ManualAnnotation as TympanaManualAnnotation,
  SuggestedAnnotation as TympanaSuggestedAnnotation,
} from "../types";

/**
 * Tympana API server assumes that the end of a Biological Sequence range is
 * exclusive. Meaning, that if a given or received range is for characters 1 to
 * 5, it includes 1, 2, 3, 4, but excludes 5. In interval notation, this would
 * be "[1, 5)". So any application-generated ranges need to increase their
 * transmitted end coordinate by one to account for this exclusion.
 *
 * Since Biological Sequences are linear, the Y-value is ignored, so only the
 * X-value needs to be adjusted.
 */
function adjustForEndExclusion(coordinate: Coordinate): Coordinate {
  return { ...coordinate, x: coordinate.x + 1 };
}

/**
 * Tympana API server uses 0-based indexes for Biological Sequences, but the
 * application uses 1-based.
 *
 * Since Biological Sequences are linear, the Y-value is ignored, so only the
 * X-value needs to be adjusted.
 */
function adjustFor0BasedIndex(coordinate: Coordinate): Coordinate {
  return { ...coordinate, x: coordinate.x - 1 };
}

export function annotationToTympanaAnnotation(
  type: Project["type"],
  annotation: ManualAnnotation
): TympanaManualAnnotation;
export function annotationToTympanaAnnotation(
  type: Project["type"],
  annotation: SuggestedAnnotation
): TympanaSuggestedAnnotation;
export function annotationToTympanaAnnotation(
  type: Project["type"],
  annotation: Annotation
): TympanaAnnotation {
  switch (type) {
    case "image":
    case "sensor":
      if (isManualAnnotation(annotation)) {
        const { id, ...tympanaAnnotation } = annotation;
        return tympanaAnnotation;
      }

      assert(isSuggestedImageAnnotation(annotation));

      return annotation;
    case "sequence":
      if (isManualAnnotation(annotation)) {
        const { id, ...tympanaAnnotation } = {
          ...annotation,
          end: adjustForEndExclusion(adjustFor0BasedIndex(annotation.end)),
          start: adjustFor0BasedIndex(annotation.start),
        };
        return tympanaAnnotation;
      }

      assert(isSuggestedSequenceAnnotation(annotation));

      return {
        ...annotation,
        data_repr: JSON.stringify(annotation.data_repr),
        end: adjustForEndExclusion(adjustFor0BasedIndex(annotation.end)),
        start: adjustFor0BasedIndex(annotation.start),
        suggested_reasoning: annotation.suggested_reasoning.map(
          (suggested) => ({
            ...suggested,
            end: adjustForEndExclusion(adjustFor0BasedIndex(suggested.end)),
            start: adjustFor0BasedIndex(suggested.start),
          })
        ),
      };
  }
}
