import { Project } from "../../../app/models/project";
import {
  Annotation,
  NewManualAnnotation,
  SuggestedImageAnnotation,
} from "../../../app/redux/features/annotations";
import { isUndefinedOrNull } from "../../../utils/assertions";
import { humanizeCoordinate } from "../../../utils/humanize-coordinate";
import {
  CoordinatePair,
  isCoordinatePair,
} from "../../../utils/is-coordinate-pair";
import { ManualAnnotation } from "../ManualAnnotation";
import { UpdateAnnotation } from "../UpdateAnnotation";
import { Form } from "./Form";
import { VisualizedAnnotation } from "./VisualizedAnnotation";
import {
  D3BrushEvent,
  axisBottom,
  axisLeft,
  brush,
  brushSelection,
  scaleLinear,
  select,
} from "d3";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const DATA_URL_REGEX = /^data:image\/.+;base64,.+/;

const Container = styled.div`
  background: ${(props) => props.theme.color.grayTT};
  position: relative;
  text-align: center;
  z-index: 10;
`;

const Chart = styled.div`
  display: inline-block;
  padding: 30px 30px 45px 60px;
  position: relative;
`;

const ChartWrap = styled.div`
  overflow-x: auto;
`;

const ChartImage = styled.img`
  display: block;
`;

const ExplanationImage = styled.img`
  display: block;
  left: 60px;
  position: absolute;
  top: 30px;
`;

const SVG = styled.svg`
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .chart-brush {
    .selection {
      fill: ${(props) => props.theme.color.mint};
      fill-opacity: 0.4;
      stroke: ${(props) => props.theme.color.mint};
      stroke-width: 2;
    }
  }
  .domain {
    opacity: 0;
  }
  .tick {
    line {
      stroke: ${(props) => props.theme.color.grayS};
      stroke-width: 1;
    }
    text {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

const CHART_MARGIN = { top: 30, right: 30, bottom: 45, left: 60 };

const onBrushEnd = (
  { selection, sourceEvent }: D3BrushEvent<unknown>,
  brushLocation: CoordinatePair | null,
  setBrushLocation: (data: CoordinatePair | null) => void
) => {
  if (isUndefinedOrNull(sourceEvent)) {
    // Why does this fire like crazy with no source?
    return;
  }

  if (isUndefinedOrNull(selection)) {
    if (brushLocation) {
      setBrushLocation(null);
    }
    return;
  }

  if (!isCoordinatePair(selection)) {
    throw new Error(`Bad selection: ${selection}`);
  }

  const x0 = Math.round(selection[0][0]);
  const y0 = Math.round(selection[0][1]);
  const x1 = Math.round(selection[1][0]);
  const y1 = Math.round(selection[1][1]);

  setBrushLocation([
    [x0, y0],
    [x1, y1],
  ]);
};

interface ImageSignature {
  addAnnotation: (annotation: NewManualAnnotation) => void;
  annotations: Annotation[];
  annotationTypes: Project["annotationTypes"];
  axes?: {
    x: { end: number; start: number };
    y: { end: number; start: number };
  };
  changeDescription: (annotation: Annotation, description: string) => void;
  changeWeight: (annotation: Annotation, weight: number) => void;
  data: string;
  explainedAnnotation?: SuggestedImageAnnotation;
  explainedAnnotationLabel?: string;
  onSelectAnnotation: (annotation: Annotation) => void;
  removeAnnotation: (annotation: Annotation) => void;
}

export const Image: FC<ImageSignature> = ({
  addAnnotation,
  annotations,
  annotationTypes,
  axes,
  changeDescription,
  changeWeight,
  data,
  explainedAnnotation,
  explainedAnnotationLabel,
  onSelectAnnotation: highlightAnnotation,
  removeAnnotation,
}) => {
  if (!DATA_URL_REGEX.test(data)) {
    throw new Error(`Malformed image data received`);
  }

  const intl = useIntl();

  const axisXRef = useRef<SVGGElement>(null);
  const axisYRef = useRef<SVGGElement>(null);
  const brushRef = useRef<SVGGElement>(null);
  const chartImageRef = useRef<HTMLImageElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const [brushLocation, setBrushLocation] = useState<CoordinatePair | null>(
    null
  );
  const [selectionLocation, setSelectionLocation] =
    useState<CoordinatePair | null>(null);
  const [description, setDescription] = useState(annotationTypes[0]);
  const [weight, setWeight] = useState(3);
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<Annotation | null>(null);

  const resetState = () => {
    setBrushLocation(null);
    setDescription(annotationTypes[0]);
    setSelectedAnnotation(null);
    setSelectionLocation(null);
    setWeight(3);
  };

  const svgHeight = svgRef.current?.clientHeight ?? 0;
  const svgWidth = svgRef.current?.clientWidth ?? 0;

  const chartHeight = useMemo(() => {
    return Math.max(svgHeight - CHART_MARGIN.top - CHART_MARGIN.bottom, 0);
  }, [svgHeight]);
  const chartWidth = useMemo(() => {
    return Math.max(svgWidth - CHART_MARGIN.left - CHART_MARGIN.right, 0);
  }, [svgWidth]);

  const chartX = useMemo(() => {
    return scaleLinear()
      .domain([
        axes?.x.start ?? 0,
        axes?.x.end ?? chartImageRef.current?.naturalWidth ?? 0,
      ])
      .range([0, chartWidth]);
  }, [axes, chartWidth]);
  const chartY = useMemo(() => {
    return scaleLinear()
      .domain([
        axes?.y.start ?? 0,
        axes?.y.end ?? chartImageRef.current?.naturalHeight ?? 0,
      ])
      .range([0, chartHeight]);
  }, [axes, chartHeight]);

  const axisX = useMemo(
    () => axisBottom(chartX).ticks(5).tickPadding(10).tickSize(5),
    [chartX]
  );
  const axisY = useMemo(
    () => axisLeft(chartY).ticks(5).tickPadding(10).tickSize(5),
    [chartY]
  );

  const brushFn = useMemo(
    () =>
      brush()
        .extent([
          [0, 0],
          [chartWidth, chartHeight],
        ])
        .on("end", (event) => {
          onBrushEnd(event, brushLocation, setBrushLocation);
        }),
    [chartHeight, chartWidth, brushLocation, setBrushLocation]
  );

  useEffect(() => {
    brushRef.current && brushFn(select(brushRef.current));
  }, [brushFn, brushRef]);

  useEffect(() => {
    axisYRef.current && axisY(select(axisYRef.current));
  }, [axisY, axisYRef]);

  useEffect(() => {
    axisXRef.current && axisX(select(axisXRef.current));
  }, [axisX, axisXRef]);

  useEffect(() => {
    if (!brushRef.current) {
      return;
    }

    if (!brushLocation) {
      setSelectionLocation(null);
      setSelectedAnnotation(null);
      brushFn.move(select(brushRef.current), null);
      return;
    }

    brushFn.move(select(brushRef.current), brushLocation);

    const roundedSelection = brushSelection(brushRef.current);

    if (
      isUndefinedOrNull(roundedSelection) ||
      !isCoordinatePair(roundedSelection)
    ) {
      throw new Error(`Bad selection: ${roundedSelection}`);
    }

    const scaledRoundedSelection: CoordinatePair = [
      [
        Math.round(chartX.invert(roundedSelection[0][0])),
        Math.round(chartY.invert(roundedSelection[0][1])),
      ],
      [
        Math.round(chartX.invert(roundedSelection[1][0])),
        Math.round(chartY.invert(roundedSelection[1][1])),
      ],
    ];

    setSelectionLocation(scaledRoundedSelection);
  }, [brushFn, brushLocation, brushRef, chartX, chartY, setSelectionLocation]);

  useEffect(() => {
    // Clear outstanding selection when annotations are saved.
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onManualAnnotationCreate = (
    location: CoordinatePair | null = selectionLocation
  ) => {
    if (!location) {
      return;
    }

    addAnnotation({
      description,
      end: { x: location[1][0], y: location[1][1] },
      source: "manual",
      start: { x: location[0][0], y: location[0][1] },
      weight,
    });

    resetState();
  };

  const onAnnotationUpdate = (annotation: Annotation) => {
    if (description) {
      changeDescription(annotation, description);
    }

    if (weight) {
      changeWeight(annotation, weight);
    }

    resetState();
  };

  const onSelectAnnotation = (annotation: Annotation) => {
    const annotationCoordinates: CoordinatePair = [
      [annotation.start.x, annotation.start.y],
      [annotation.end.x, annotation.end.y],
    ];
    setWeight(annotation.weight);
    setDescription(annotation.description);
    setSelectedAnnotation(annotation);
    setSelectionLocation(annotationCoordinates);
    setBrushLocation(annotationCoordinates);
  };

  const onHighlightAnnotation = () => {
    if (!selectedAnnotation) {
      return;
    }

    highlightAnnotation(selectedAnnotation);
  };

  const onRemoveAnnotation = () => {
    if (!selectedAnnotation) {
      return;
    }

    removeAnnotation(selectedAnnotation);
    resetState();
  };

  return (
    <Container>
      <ChartWrap>
        <Chart>
          <ChartImage alt="Selectable Data" ref={chartImageRef} src={data} />
          {explainedAnnotation && (
            <ExplanationImage
              alt={explainedAnnotationLabel}
              src={explainedAnnotation.data_repr}
            />
          )}
          <SVG ref={svgRef}>
            <g
              ref={axisXRef}
              transform={`translate(${CHART_MARGIN.left}, ${
                chartHeight + CHART_MARGIN.top
              })`}
            />
            <g
              ref={axisYRef}
              transform={`translate(${CHART_MARGIN.left}, ${CHART_MARGIN.top})`}
            />
            <g
              transform={`translate(${CHART_MARGIN.left}, ${CHART_MARGIN.top})`}
            >
              <g className="chart-brush" ref={brushRef} />
              {annotations.map((annotation) => (
                <VisualizedAnnotation
                  height={chartY(annotation.end.y) - chartY(annotation.start.y)}
                  label={intl.formatMessage(
                    { id: "Visualization of {name} at {location}" },
                    {
                      location: humanizeCoordinate("image", annotation.start),
                      name: annotation.description,
                    }
                  )}
                  key={annotation.id}
                  onSelect={() => onSelectAnnotation(annotation)}
                  width={chartX(annotation.end.x) - chartX(annotation.start.x)}
                  x={chartX(annotation.start.x)}
                  y={chartY(annotation.start.y)}
                />
              ))}
            </g>
          </SVG>
        </Chart>
      </ChartWrap>
      {!selectedAnnotation && selectionLocation && brushRef.current && (
        <ManualAnnotation
          annotationTypes={annotationTypes}
          description={description}
          end={selectionLocation[1]}
          onSubmit={onManualAnnotationCreate}
          reference={brushRef.current.getElementsByClassName("selection")[0]}
          setDescription={setDescription}
          setWeight={setWeight}
          start={selectionLocation[0]}
          weight={weight}
        />
      )}
      {selectedAnnotation && selectionLocation && brushRef.current && (
        <UpdateAnnotation
          annotationTypes={annotationTypes}
          deleteAnnotation={() => onRemoveAnnotation()}
          description={description}
          end={selectionLocation[1]}
          highlightAnnotation={() => onHighlightAnnotation()}
          onSubmit={() => {
            onAnnotationUpdate(selectedAnnotation);
          }}
          reference={brushRef.current.getElementsByClassName("selection")[0]}
          setDescription={setDescription}
          setWeight={setWeight}
          start={selectionLocation[0]}
          weight={weight}
        />
      )}
      <Form
        annotationTypes={annotationTypes}
        description={description}
        maxX={chartWidth}
        maxY={chartHeight}
        minX={0}
        minY={0}
        onSubmit={onManualAnnotationCreate}
        selection={selectionLocation}
        setDescription={setDescription}
        setWeight={setWeight}
        weight={weight}
      />
    </Container>
  );
};
