import ProtectedRoute from "./components/ProtectedRoute";
import Annotations from "./pages/Annotations";
import Dashboard from "./pages/Dashboard";
import Export from "./pages/Export";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Route, Switch } from "react-router-dom";

const App: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Tympana" })}</title>
      </Helmet>
      <Switch>
        <Route path="/dashboard">
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        </Route>
        <Route path="/projects/:projectId/annotations">
          <ProtectedRoute>
            <Annotations />
          </ProtectedRoute>
        </Route>
        <Route path="/projects/:projectId/export">
          <ProtectedRoute>
            <Export />
          </ProtectedRoute>
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default App;
