import { resetStateAction } from "../app/redux/actions/resetState";
import { useTypedDispatch } from "../app/redux/store";
import { IAuthenticator } from "./auth";

export const LocalAuthenticator: IAuthenticator = {
  AuthenticationProvider: ({ children }) => {
    return <>{children}</>;
  },

  Authenticator: () => {
    throw new Error(
      "Unexpected unauthenticated with local development authentication configured"
    );
  },

  useAuth: () => {
    const dispatch = useTypedDispatch();

    return {
      isAuthenticated: true,
      signOut: () => {
        dispatch(resetStateAction());
      },
      user: {
        id: "local1",
        token: "local.token.value",
        username: "local-username",
      },
    };
  },
};
