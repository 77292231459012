import { SuggestedAnnotation } from "../app/redux/features/annotations";
import Form from "./Form";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export type SuggestedReasoningWeightChangeHandler = (
  annotation: SuggestedAnnotation,
  suggestedReasoning: SuggestedAnnotation["suggested_reasoning"][0],
  weight: number
) => void;

interface SuggestedReasoningWeightSignature {
  annotation: SuggestedAnnotation;
  suggestedReasoning: SuggestedAnnotation["suggested_reasoning"][0];
  onChange?: SuggestedReasoningWeightChangeHandler;
}

export const SuggestedReasoningWeight: FC<
  SuggestedReasoningWeightSignature
> = ({ annotation, onChange, suggestedReasoning }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label>
        <span className="srt">
          <FormattedMessage
            id="Weight of {annotationName} at {annotationLocation}, {suggestedName} at {suggestedLocation}"
            values={{
              annotationLocation: annotation.start.x,
              annotationName: annotation.description,
              suggestedLocation: suggestedReasoning.start.x,
              suggestedName: suggestedReasoning.description,
            }}
          />
        </span>
        <Form.RangeWrap>
          <Form.Range
            defaultValue={suggestedReasoning.weight}
            min={1}
            max={5}
            onChange={(e) => {
              onChange?.(
                annotation,
                suggestedReasoning,
                parseInt(e.target.value, 10)
              );
            }}
            step={1}
            type="range"
          />
        </Form.RangeWrap>
      </label>
    </form>
  );
};
