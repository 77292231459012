import { FC } from "react";
import styled from "styled-components";

export const iconset = {
  annotatedData: () => (
    <>
      <rect width="4" height="4" fill="#192952" />
      <path d="M3.5 1.25H12.5V2.75H3.5V1.25Z" fill="#192952" />
      <path d="M3.5 13.25H12.5V14.75H3.5V13.25Z" fill="#192952" />
      <path d="M2.75 3.5L2.75 12.5H1.25L1.25 3.5H2.75Z" fill="#192952" />
      <path d="M14.75 3.5V12.5H13.25V3.5H14.75Z" fill="#192952" />
      <rect x="12" width="4" height="4" fill="#192952" />
      <rect x="12" y="12" width="4" height="4" fill="#192952" />
      <rect y="12" width="4" height="4" fill="#192952" />
    </>
  ),
  arrowLeft: () => (
    <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" />
  ),
  arrowRight: () => (
    <path d="M-6.11959e-07 9L12.17 9L6.58 14.59L8 16L16 8L8 6.99382e-07L6.59 1.41L12.17 7L-7.86805e-07 7L-6.11959e-07 9Z" />
  ),
  check: () => (
    <path d="M5.08459 11.6237L1.29152 7.83059L-0.00012207 9.11313L5.08459 14.1978L15.9999 3.28255L14.7173 2L5.08459 11.6237Z" />
  ),
  delete: () => (
    <path d="M2.88889 14.2222C2.88889 15.2 3.68889 16 4.66667 16H11.7778C12.7556 16 13.5556 15.2 13.5556 14.2222V3.55556H2.88889V14.2222ZM14.4444 0.888889H11.3333L10.4444 0H6L5.11111 0.888889H2V2.66667H14.4444V0.888889Z" />
  ),
  dockerInstance: () => (
    <>
      <rect y="8.53333" width="3.2" height="3.2" />
      <rect y="12.8" width="3.2" height="3.2" />
      <rect x="4.26666" y="8.53333" width="3.2" height="3.2" />
      <rect x="4.26666" y="12.8" width="3.2" height="3.2" />
      <rect x="8.53333" y="8.53333" width="3.2" height="3.2" />
      <rect x="8.53333" y="12.8" width="3.2" height="3.2" />
      <rect x="12.8" y="8.53333" width="3.2" height="3.2" />
      <rect x="12.8" y="12.8" width="3.2" height="3.2" />
      <rect x="8.53333" y="4.26672" width="3.2" height="3.2" />
      <rect x="8.53333" width="3.2" height="3.2" />
      <rect x="4.26666" y="4.26672" width="3.2" height="3.2" />
    </>
  ),
  download: () => (
    <>
      <path d="M7.25 5.91225e-07L7.25 9.1275L3.0575 4.935L2 6L8 12L14 6L12.9425 4.9425L8.75 9.1275L8.75 5.25658e-07L7.25 5.91225e-07Z" />
      <rect x="1" y="14" width="14" height="1.5" />
    </>
  ),
  edit: () => (
    <path d="M0 12.6671V16H3.33287L13.1626 6.17025L9.82975 2.83738L0 12.6671ZM15.74 3.59283C16.0867 3.24622 16.0867 2.68629 15.74 2.33968L13.6603 0.259964C13.3137 -0.0866546 12.7538 -0.0866546 12.4072 0.259964L10.7807 1.8864L14.1136 5.21927L15.74 3.59283V3.59283Z" />
  ),
  search: () => (
    <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629V10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" />
  ),
  suggested: () => (
    <>
      <path d="M14.9558 7.41731C14.6237 7.24498 14.2902 7.09164 13.9552 6.95728C13.3049 6.69478 12.636 6.48098 11.9541 6.31763C11.3093 6.16218 10.6556 6.04659 9.99672 5.97152C9.92167 5.31227 9.8064 4.65825 9.65156 4.01314C9.48818 3.32953 9.27498 2.65887 9.01365 2.00657C8.87966 1.6736 8.73402 1.33917 8.57673 1.00329C8.40281 0.65415 8.20271 0.318757 7.97815 0C7.75359 0.318757 7.55349 0.65415 7.37957 1.00329C7.20772 1.33625 7.06208 1.67068 6.94265 2.00657C6.68133 2.65887 6.46812 3.32953 6.30475 4.01314C6.14975 4.6597 6.03448 5.31518 5.95958 5.9759C5.30067 6.05101 4.64697 6.16659 4.00218 6.32202C3.32044 6.48584 2.65161 6.69963 2.00109 6.96166C1.66903 7.09602 1.33552 7.24206 1.00055 7.39978C0.652363 7.57418 0.317887 7.77483 0 8C0.317887 8.22517 0.652363 8.42582 1.00055 8.60022C1.3326 8.77254 1.66612 8.91858 2.00109 9.03833C2.65161 9.30037 3.32044 9.51416 4.00218 9.67798C4.64554 9.83323 5.29777 9.94882 5.95522 10.0241C6.03009 10.6848 6.14536 11.3403 6.30038 11.9869C6.4633 12.6706 6.67651 13.3413 6.93828 13.9934C7.07227 14.3264 7.2252 14.6608 7.39705 14.9967C7.57219 15.3461 7.77377 15.6815 8 16C8.22625 15.6815 8.42782 15.3461 8.60295 14.9967C8.7748 14.6637 8.92773 14.3293 9.06171 13.9934C9.3235 13.3413 9.53672 12.6706 9.69962 11.9869C9.85447 11.3417 9.96974 10.6877 10.0448 10.0285C10.7022 9.95323 11.3545 9.83764 11.9978 9.68237C12.6797 9.51901 13.3485 9.3052 13.9989 9.04272C14.331 8.90836 14.6645 8.75502 14.9995 8.58269C15.3479 8.40707 15.6824 8.20495 16 7.97809C15.6669 7.76434 15.3178 7.57684 14.9558 7.41731V7.41731Z" />
      <ellipse cx="14.455" cy="1.45496" rx="1.45495" ry="1.45496" />
    </>
  ),
  manual: () => (
    <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z" />
  ),
};

// Component

interface IconProps {
  id: keyof typeof iconset;
  title?: string;
}

const Icon: FC<IconProps> = ({ id, title }) => {
  const SvgContent = iconset[id];
  return (
    <Svg
      role="img"
      title={title}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <SvgContent />
    </Svg>
  );
};

// Styles

interface SvgProps {
  title?: string;
}

const Svg = styled.svg<SvgProps>`
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  height: 1em;
  pointer-events: none;
  vertical-align: -0.175em;
  width: 1em;
`;

export default Icon;
