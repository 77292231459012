import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const Container = styled.table`
  border-collapse: collapse;
  min-width: 100%;
`;

const Overflow = styled.div`
  overflow: auto;
`;

/* Sets */

const TBody = styled.tbody`
  > tr {
    border-bottom: 1px solid ${(props) => props.theme.color.grayT};
  }
`;

const THead = styled.thead`
  border-bottom: 2px solid ${(props) => props.theme.color.gray};
`;

/* Pieces */

interface TDProps {
  $nowrap?: boolean;
  $state?: "empty" | "error" | "loading";
}

const TD = styled.td<TDProps>`
  padding: 1rem;

  ${(props) =>
    props.$nowrap &&
    css`
      white-space: nowrap;
    `}

  ${(props) =>
    props.$state &&
    css`
      padding-bottom: 2rem;
      padding-top: 2rem;
      text-align: center;
    `}
`;

const TH = styled.th`
  padding: 0 1rem 1rem;
  text-align: left;
`;

const TRExpand = styled.tr`
  background: ${(props) => props.theme.color.grayTT};
`;

/* Ancillary */

const Actions = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 25em) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Meta = styled.span`
  color: ${(props) => props.theme.color.grayS};
  display: block;
  font-size: ${(props) => props.theme.size.down2};
`;

const More = styled(Link)`
  font-size: ${(props) => props.theme.size.up1};
  font-weight: bold;
  text-decoration: none;
  &::after {
    content: " →";
  }
`;

const Table = {
  Container,
  Overflow,
  TBody,
  THead,
  TD,
  TH,
  TRExpand,
  Actions,
  Header,
  More,
  Meta,
};

export default Table;
