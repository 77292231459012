import { Project } from "../app/models/project";
import { Coordinate } from "../app/redux/features/annotations";

export function humanizeCoordinate(
  type: Project["type"],
  coordinate: Coordinate
): string {
  switch (type) {
    case "image":
    case "sensor":
      return `(${coordinate.x}, ${coordinate.y})`;
    case "sequence":
      return coordinate.x.toString();
  }
}
