export class PriorityQueue<T = unknown> {
  private _elements: T[] = [];

  constructor(private readonly _priorityFn: (a: T, b: T) => number) {}

  /**
   * Adds the given element to the queue.
   */
  add(element: T): void {
    this._elements.push(element);
    this.resort();
  }

  /**
   * Returns the number of objects in the queue.
   */
  get length(): number {
    return this._elements.length;
  }

  /**
   * Returns the highest priority element in the queue.
   */
  peek(): T {
    return this._elements[0];
  }

  /**
   * Reorders the internal queue. This may be necessary when there are external
   * modifications of the contained objects which affect the given priority
   * algorithm.
   */
  resort(): void {
    this._elements = this._elements.sort(this._priorityFn);
  }

  /**
   * Returns a prioritized (highest first) list of elements in the queue.
   */
  toArray(): T[] {
    return [...this._elements];
  }
}
