import { Project as ProjectModel } from "../app/models/project";
import {
  useGetProjectsQuery,
  useResetProjectMutation,
} from "../app/redux/api/tympana";
import { selectCurrentUser } from "../app/redux/features/auth";
import { Project, selectAllProjects } from "../app/redux/features/projects";
import { useTypedSelector } from "../app/redux/store";
import Content from "../components/Content";
import { ExportButton } from "../components/ExportButton";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Progress from "../components/Progress";
import RelativeTime from "../components/RelativeTime";
import Row from "../components/Row";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import Text from "../components/Text";
import { assert, isUndefined } from "../utils/assertions";
import { resetProject as gtmResetProject } from "../utils/google-tag-manager";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

const Reset = styled.button`
  background: transparent;
  color: ${(props) => props.theme.color.blueSS};
  display: inline-block;
  font-weight: bold;
  padding: 1rem;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.blueT};
  }
`;

const Type: Record<Project["type"], string> = {
  image: "Image Data",
  sensor: "Sensor Data",
  sequence: "Biological Sequence",
};

const Dashboard: FC = () => {
  const intl = useIntl();
  const currentUser = useTypedSelector(selectCurrentUser);
  const getProjectsQueryData = { userId: currentUser?.id || "userId" };
  const { isError, isLoading, isSuccess } = useGetProjectsQuery(
    getProjectsQueryData,
    { skip: isUndefined(currentUser) }
  );
  const projects = useTypedSelector(selectAllProjects);
  const [resetProjectTrigger, { isLoading: isResetLoading }] =
    useResetProjectMutation();

  const resetProject = async (project: ProjectModel) => {
    if (
      !window.confirm(
        intl.formatMessage({
          id: "Resetting this project will remove all manually created annotations and reset your annotation progress. Are you sure that you'd like to proceed and lose all project data?",
        })
      )
    ) {
      return;
    }

    assert(!isUndefined(currentUser));

    gtmResetProject({ projectId: project.id });
    await resetProjectTrigger({
      projectId: project.id,
      userId: currentUser.id,
    });
  };

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Dashboard – Tympana" })}</title>
      </Helmet>
      <Nav />
      <Header.Container>
        <Row>
          <Header.Content>
            <Text.H1>
              <FormattedMessage id="Projects" />
            </Text.H1>
          </Header.Content>
        </Row>
      </Header.Container>
      <Content>
        <Row>
          <Table.Overflow>
            <Table.Container>
              <Table.THead>
                <tr>
                  <Table.TH>
                    <FormattedMessage id="Name" />
                  </Table.TH>
                  <Table.TH>
                    <FormattedMessage id="Type" />
                  </Table.TH>
                  <Table.TH>
                    <FormattedMessage id="Progress" />
                  </Table.TH>
                  <Table.TH>
                    <FormattedMessage id="Confidence" />
                  </Table.TH>
                  <Table.TH>
                    <span className="srt">
                      <FormattedMessage id="Actions" />
                    </span>
                  </Table.TH>
                </tr>
              </Table.THead>

              <Table.TBody>
                {(isLoading || isUndefined(currentUser)) && (
                  <tr>
                    <Table.TD colSpan={6} $state="loading">
                      <Spinner />
                        
                      <FormattedMessage id="Loading Projects" />
                    </Table.TD>
                  </tr>
                )}
                {isError && (
                  <tr>
                    <Table.TD colSpan={6} $state="error">
                      <FormattedMessage id="There was an error loading projects" />
                    </Table.TD>
                  </tr>
                )}
                {isSuccess &&
                  projects.map((project) => (
                    <tr key={project.id}>
                      <Table.TD>
                        <Table.More to={`/projects/${project.id}/annotations`}>
                          {project.name}
                        </Table.More>
                        <Table.Meta>
                          {project.updatedAt && (
                            <RelativeTime date={project.updatedAt} />
                          )}
                        </Table.Meta>
                      </Table.TD>
                      <Table.TD>{Type[project.type]}</Table.TD>
                      <Table.TD>
                        <Progress
                          $count={`${project.progress.annotationsDone} / ${project.progress.totalInstances}`}
                          $progress={project.progressPercentage}
                        />
                      </Table.TD>
                      <Table.TD>
                        <Progress
                          $export={project.confidence.exportThreshold * 100}
                          $progress={project.confidence.value}
                        />
                      </Table.TD>
                      <Table.TD>
                        <Table.Actions>
                          <ExportButton project={project} />
                          <Reset
                            disabled={isResetLoading}
                            onClick={() => {
                              resetProject(project);
                            }}
                          >
                            Reset
                          </Reset>
                        </Table.Actions>
                      </Table.TD>
                    </tr>
                  ))}
                {isSuccess && projects.length === 0 && (
                  <tr>
                    <Table.TD colSpan={6} $state="empty">
                      <FormattedMessage id="Your project list is currently empty" />
                    </Table.TD>
                  </tr>
                )}
              </Table.TBody>
            </Table.Container>
          </Table.Overflow>
        </Row>
      </Content>
    </>
  );
};

export default Dashboard;
