import { signIn, signOut } from "../app/redux/features/auth";
import { useTypedDispatch } from "../app/redux/store";
import { Authenticator, useAuth } from "../contexts/auth";
import { login, logout } from "../utils/google-tag-manager";
import { FC, ReactElement, useEffect } from "react";

interface ProtectedRouteSignature {
  children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteSignature> = ({ children }) => {
  const auth = useAuth();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(
        signIn({
          bearerToken: auth.user.token,
          id: auth.user.id,
          username: auth.user.username,
        })
      );
      login(auth.user.id);
    } else {
      dispatch(signOut());
      logout();
    }
  }, [auth.isAuthenticated, auth.user, dispatch]);

  if (!auth.isAuthenticated) {
    return <Authenticator />;
  }

  return children;
};

export default ProtectedRoute;
