export interface Coordinate {
  x: number;
  y: number;
}

interface BaseAnnotation {
  description: string;
  end: Coordinate;
  id: string;
  start: Coordinate;
  weight: number;
}

export type ManualAnnotation = BaseAnnotation & {
  source: "manual";
};

export type NewManualAnnotation = Omit<ManualAnnotation, "id">;

export interface SequenceSuggestionRange {
  end: Coordinate;
  start: Coordinate;
  weight: number;
}

interface SequenceSuggestion {
  data_repr: SequenceSuggestionRange[];
  repr_format: "json";
}

interface ImageSuggestion {
  data_repr: string;
  repr_format: "base64";
}

type BaseSuggestedAnnotation = BaseAnnotation & {
  source: "suggested";
  suggested_reasoning: {
    description: string;
    end: Coordinate;
    id: string;
    start: Coordinate;
    weight: number;
  }[];
};

export type SuggestedImageAnnotation = BaseSuggestedAnnotation &
  ImageSuggestion;

export type SuggestedSequenceAnnotation = BaseSuggestedAnnotation &
  SequenceSuggestion;

export type SuggestedAnnotation =
  | SuggestedImageAnnotation
  | SuggestedSequenceAnnotation;

export type Annotation = ManualAnnotation | SuggestedAnnotation;

export type State = {
  annotations: Annotation[];
  isLocallyModified: boolean;
};

export function isManualAnnotation(
  annotation: Annotation
): annotation is ManualAnnotation {
  return annotation.source === "manual";
}

export function isSuggestedAnnotation(
  annotation: Annotation
): annotation is SuggestedAnnotation {
  return annotation.source === "suggested";
}

export function isSuggestedImageAnnotation(
  annotation: Annotation
): annotation is SuggestedImageAnnotation {
  return (
    isSuggestedAnnotation(annotation) && annotation.repr_format === "base64"
  );
}

export function isSuggestedSequenceAnnotation(
  annotation: Annotation
): annotation is SuggestedSequenceAnnotation {
  return isSuggestedAnnotation(annotation) && annotation.repr_format === "json";
}
