import Spinner from "./Spinner";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  min-height: 100vh;
  padding: 2rem;
`;

const Content = styled.div`
  margin: auto;
`;

const Loading: FC = () => {
  return (
    <Container>
      <Content>
        <Spinner />
          
        <FormattedMessage id="Loading" />
      </Content>
    </Container>
  );
};

export default Loading;
