import { isUndefined } from "../../../utils/assertions";
import { Coordinate } from "../../../utils/is-coordinate-pair";
import { FC, FormEventHandler, useEffect, useState } from "react";

interface FormSignature {
  annotationTypes: string[];
  defaultEndX?: number;
  defaultStartX?: number;
  description: string;
  maxX: number;
  minX: number;
  onSubmit: (coordinate: Coordinate) => void;
  setDescription: (description: string) => void;
  setWeight: (weight: number) => void;
  weight: number;
}

export const Form: FC<FormSignature> = ({
  annotationTypes,
  defaultEndX,
  defaultStartX,
  description,
  maxX,
  minX,
  onSubmit,
  setDescription,
  setWeight,
  weight,
}) => {
  const [endX, setEndX] = useState<number>();
  const [startX, setStartX] = useState<number>();

  useEffect(() => {
    if (isUndefined(defaultEndX) || isUndefined(defaultStartX)) {
      setEndX(undefined);
      setStartX(undefined);
      return;
    }

    setEndX(defaultEndX);
    setStartX(defaultStartX);
  }, [defaultEndX, defaultStartX]);

  const onFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (startX && endX) {
      onSubmit([startX, endX]);

      setEndX(undefined);
      setStartX(undefined);
    }
  };

  return (
    <form className="srt" onSubmit={onFormSubmit}>
      <label>
        <span>New Annotation Start X</span>
        <input
          defaultValue={startX}
          max={maxX}
          min={minX}
          onChange={(e) => {
            setStartX(parseInt(e.target.value, 10));
          }}
          required={true}
          type="number"
        ></input>
      </label>
      <label>
        <span>New Annotation End X</span>
        <input
          defaultValue={endX}
          max={maxX}
          min={minX}
          onChange={(e) => {
            setEndX(parseInt(e.target.value, 10));
          }}
          required={true}
          type="number"
        ></input>
      </label>
      <label>
        <span>New Annotation Description</span>
        <select
          onChange={(e) => {
            setDescription(e.target.selectedOptions[0].value);
          }}
          required={true}
          value={description}
        >
          {annotationTypes.map((a) => (
            <option key={a} value={a}>
              {a}
            </option>
          ))}
        </select>
      </label>
      <label>
        <span>New Annotation Weight</span>
        <input
          max={5}
          min={1}
          onChange={(e) => {
            setWeight(parseInt(e.target.value, 10));
          }}
          step={1}
          type="range"
          value={weight}
        ></input>
      </label>
      <button type="submit">Add New Annotation</button>
    </form>
  );
};
