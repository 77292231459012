import { Project } from "../types";
import { Factory } from "fishery";

class ProjectFactory extends Factory<Project> {
  biologicalSequence() {
    return this.params({ type: "sequence" });
  }

  exportInProgress() {
    return this.params({
      confidence: { export_threshold: 0.5, value: 0.6 },
      model_export: { status: "processing" },
    });
  }

  exportable() {
    return this.params({ confidence: { export_threshold: 0.5, value: 0.6 } });
  }

  image() {
    return this.params({ type: "image" });
  }

  sensor() {
    return this.params({ type: "sensor" });
  }

  unexportable() {
    return this.params({ confidence: { export_threshold: 0.6, value: 0.5 } });
  }
}

export const factory = ProjectFactory.define(({ sequence }) => ({
  annotation_types: ["Annotation Type 1", "Annotation Type 2"],
  confidence: {
    estimated_remaining_samples: 50,
    export_threshold: 0.8,
    value: 0.5,
  },
  id: `project-${sequence}`,
  name: `Project ${sequence}`,
  progress: {
    annotations_done: 250,
    total_instances: 500,
  },
  type: "image" as const,
}));
