export function assert(
  condition: unknown,
  message?: string
): asserts condition {
  if (!condition) {
    throw new Error(
      message ? `Assertion failed: ${message}` : "Assertion failed"
    );
  }
}

/**
 * Returns whether the provided value is `undefined`.
 */
export function isUndefined(obj: unknown): obj is undefined {
  return typeof obj === "undefined";
}

/**
 * Returns whether the provided value is `undefined` or `null`.
 */
export function isUndefinedOrNull(obj: unknown): obj is undefined | null {
  return isUndefined(obj) || obj === null;
}

/**
 * Asserts that the argument passed in is neither `undefined` nor `null`.
 */
export function assertIsDefined<T>(
  obj: T | null | undefined,
  message?: string
): T {
  assert(!isUndefinedOrNull(obj), message ?? "argument is undefined or null");

  return obj;
}
