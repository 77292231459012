import { assert } from "../../../../../utils/assertions";
import { Coordinate, SuggestedAnnotation } from "../../../features/annotations";
import { Project } from "../../../features/projects";
import {
  Annotation as TympanaAnnotation,
  isManualAnnotation as isManualTympanaAnnotation,
  isSuggestedImageAnnotation,
  isSuggestedSequenceAnnotation,
} from "../types";

/**
 * Tympana API server assumes that the end of a Biological Sequence range is
 * exclusive. Meaning, that if a given or received range is for characters 1 to
 * 5, it includes 1, 2, 3, 4, but excludes 5. In interval notation, this would
 * be "[1, 5)". So any application-generated ranges need to increase their
 * transmitted end coordinate by one to account for this exclusion.
 *
 * Since Biological Sequences are linear, the Y-value is ignored, so only the
 * X-value needs to be adjusted.
 */
function adjustForEndExclusion(coordinate: Coordinate): Coordinate {
  return { ...coordinate, x: coordinate.x - 1 };
}

/**
 * Tympana API server uses 0-based indexes for Biological Sequences, but the
 * application uses 1-based.
 *
 * Since Biological Sequences are linear, the Y-value is ignored, so only the
 * X-value needs to be adjusted.
 */
function adjustFor0BasedIndex(coordinate: Coordinate): Coordinate {
  return { ...coordinate, x: coordinate.x + 1 };
}

/**
 * This coerces Tympana API Annotation responses into suggested annotations
 * within the application.
 *
 * Currently, there is not an API interface to query for previously persisted
 * manual annotations, so the API only ever returns suggested annotations.
 */
export function tympanaAnnotationToAnnotation(
  type: Project["type"],
  annotation: TympanaAnnotation
): SuggestedAnnotation {
  if (isManualTympanaAnnotation(annotation)) {
    throw new Error("Unexpected manual annotation received through the API");
  }

  switch (type) {
    case "image":
    case "sensor":
      assert(isSuggestedImageAnnotation(annotation));

      return annotation;
    case "sequence":
      assert(isSuggestedSequenceAnnotation(annotation));

      return {
        ...annotation,
        data_repr: JSON.parse(annotation.data_repr),
        end: adjustForEndExclusion(adjustFor0BasedIndex(annotation.end)),
        start: adjustFor0BasedIndex(annotation.start),
        suggested_reasoning: annotation.suggested_reasoning.map(
          (suggested) => ({
            ...suggested,
            end: adjustForEndExclusion(adjustFor0BasedIndex(suggested.end)),
            start: adjustFor0BasedIndex(suggested.start),
          })
        ),
      };
  }
}
