const theme = {
  color: {
    blueTT: "#00AEEF",
    blueT: "#0052A4",
    blue: "#364876",
    blueS: "#143162",
    blueSS: "#192952",
    grayTT: "#EFF1F3",
    grayT: "#DDE2E9",
    gray: "#CCCCCC",
    grayS: "#707070",
    mint: "#66DDC0",
    white: "#FFFFFF",
  },
  ease: {
    quintOut: "cubic-bezier(0.23, 1, 0.32, 1)",
  },
  font: {
    sans: "Rubik, sans-serif",
  },
  size: {
    up5: "3rem",
    up4: "2.5rem",
    up3: "2rem",
    up2: "1.5rem",
    up1: "1.25rem",
    base: "1rem",
    down1: "0.875rem",
    down2: "0.75rem",
  },
};

export default theme;
