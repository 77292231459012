import Content from "../components/Content";
import Header from "../components/Header";
import Icon from "../components/Icon";
import Nav from "../components/Nav";
import Row from "../components/Row";
import Text from "../components/Text";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

const P = styled.p`
  font-size: ${(props) => props.theme.size.up1};
  line-height: 2rem;
`;

const NotFound: FC = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Page Not Found – Tympana" })}</title>
      </Helmet>
      <Nav />
      <Header.Container>
        <Row>
          <Header.Back to="/dashboard">
            <Icon id="arrowLeft" /> <FormattedMessage id="Back" />
          </Header.Back>
          <Header.Content>
            <Text.H1>
              <FormattedMessage id="Page Not Found" />
            </Text.H1>
          </Header.Content>
        </Row>
      </Header.Container>

      <Content>
        <Row>
          <P>
            <FormattedMessage id="Sorry, but the requested page was not found." />{" "}
            <Link to="/dashboard">
              <FormattedMessage id="Head back to the dashboard" />
            </Link>
            .
          </P>
        </Row>
      </Content>
    </>
  );
};

export default NotFound;
