import {
  AUTHENTICATION_METHOD,
  GTM_CONTAINER_AUTH,
  GTM_CONTAINER_ID,
  GTM_CONTAINER_PREVIEW,
} from "./environment";
import TagManager from "react-gtm-module";

let initialized = false;

/**
 * These are Custom Events which have been manually registered within the Google
 * Tag Manager Container, along with any of their custom Data Layer Variables
 * and Tags.
 *
 * @see https://support.google.com/tagmanager/answer/7679219?hl=en
 */
enum GTMCustomEvent {
  AnnotationsPersisted = "annotations_persisted",
  Login = "login",
  ManualAnnotationCreated = "manual_annotation_created",
  ProjectDataDownloaded = "project_data_downloaded",
  ProjectModelDownloaded = "project_model_downloaded",
  ProjectReset = "project_reset",
  SuggestedAnnotationChanged = "suggested_annotation_changed",
  SuggestedAnnotationRemoved = "suggested_annotation_removed",
}

type AnnotationsPersistedParameters = { projectId: string };
type LoginParameters = { method: string };
type ManualAnnotationCreatedParameters = {
  annotationDescription: string;
  annotationWeight: number;
  projectId: string;
};
type ProjectDataDownloadedParameters = { projectId: string };
type ProjectModelDownloadedParameters = { projectId: string };
type ProjectResetParameters = { projectId: string };
type SuggestedAnnotationChangedParameters = {
  annotationDescription: string;
  annotationId: string;
  annotationWeight: number;
  projectId: string;
};
type SuggestedAnnotationRemovedParameters = {
  annotationDescription: string;
  annotationId: string;
  annotationWeight: number;
  projectId: string;
};

/**
 * Notifies Google Tag Manager when a set of a Project's Annotations are
 * persisted.
 */
export function annotationsPersisted(projectId: string): void {
  trackEvent(GTMCustomEvent.AnnotationsPersisted, { projectId });
}

/**
 * Initialize Google Tag Manager for the current build environment.
 */
export function initialize(): void {
  if (initialized || !GTM_CONTAINER_ID) {
    return;
  }

  TagManager.initialize({
    auth: GTM_CONTAINER_AUTH,
    gtmId: GTM_CONTAINER_ID,
    preview: GTM_CONTAINER_PREVIEW,
  });

  initialized = true;
}

/**
 * Provide the current user identifier to Google Tag Manager to allow Google
 * Analytics to correlated multiple user sessions and devices.
 *
 * This also emits a Google Tag Manager `login` event. This event name and data
 * is recommended by GTM and GA.
 *
 * @see https://support.google.com/analytics/answer/9267735.
 */
export function login(
  userId: string,
  method: string = AUTHENTICATION_METHOD
): void {
  setUserId(userId);
  trackEvent(GTMCustomEvent.Login, { method });
}

/**
 * Remove the current user identifier from Google Tag Manager's data layer.
 */
export function logout(): void {
  setUserId(null);
}

/**
 * Notifies Google Tag Manager when a user manually creates a new Project
 * Annotation.
 */
export function manualAnnotationCreated({
  annotationDescription,
  annotationWeight,
  projectId,
}: ManualAnnotationCreatedParameters): void {
  trackEvent(GTMCustomEvent.ManualAnnotationCreated, {
    annotationDescription,
    annotationWeight,
    projectId,
  });
}

/**
 * Notifies Google Tag Manager when a user exports annotated project data.
 */
export function projectDataDownloaded({
  projectId,
}: ProjectDataDownloadedParameters): void {
  trackEvent(GTMCustomEvent.ProjectDataDownloaded, { projectId });
}

/**
 * Notifies Google Tag Manager when a user exports a project models (Docker
 * instance).
 */
export function projectModelDownloaded({
  projectId,
}: ProjectModelDownloadedParameters): void {
  trackEvent(GTMCustomEvent.ProjectModelDownloaded, { projectId });
}

/**
 * Notifies Google Tag Manager when a user resets a project.
 */
export function resetProject({ projectId }: ProjectResetParameters): void {
  trackEvent(GTMCustomEvent.ProjectReset, { projectId });
}

/**
 * Provide the current user identifier to Google Tag Manager to allow Google
 * Analytics to correlated multiple user sessions and devices.
 */
function setUserId(userId: null | string): void {
  TagManager.dataLayer({ dataLayer: { userId } });
}

/**
 * Notifies Google Tag Manager when a user changes a suggested Project
 * Annotation.
 */
export function suggestedAnnotationChanged({
  annotationDescription,
  annotationId,
  annotationWeight,
  projectId,
}: SuggestedAnnotationChangedParameters): void {
  trackEvent(GTMCustomEvent.SuggestedAnnotationChanged, {
    annotationDescription,
    annotationId,
    annotationWeight,
    projectId,
  });
}

/**
 * Notifies Google Tag Manager when a user removes a suggested Project
 * Annotation.
 */
export function suggestedAnnotationRemoved({
  annotationDescription,
  annotationId,
  annotationWeight,
  projectId,
}: SuggestedAnnotationRemovedParameters): void {
  trackEvent(GTMCustomEvent.SuggestedAnnotationRemoved, {
    annotationDescription,
    annotationId,
    annotationWeight,
    projectId,
  });
}

/**
 * Provide a custom event to Google Tag Manager with optional event parameters.
 *
 * **Important Note:** Simply pushing new events into the GTM dataLayer only
 * send that information as an unknown quantity to GTM. You must also capture
 * Data Layer variables, create Custom Event Triggers, and create custom Tags
 * within GTM to forward this data on to other systems like Google Analytics.
 * Further, for Google Analytics, if you're providing new custom parameters, you
 * will need to define Custom Dimensions in each GA Property in order to report
 * on them.
 */
function trackEvent(
  name: GTMCustomEvent.AnnotationsPersisted,
  parameters: AnnotationsPersistedParameters
): void;
function trackEvent(
  name: GTMCustomEvent.Login,
  parameters: LoginParameters
): void;
function trackEvent(
  name: GTMCustomEvent.ManualAnnotationCreated,
  parameters: ManualAnnotationCreatedParameters
): void;
function trackEvent(
  name: GTMCustomEvent.ProjectDataDownloaded,
  parameters: ProjectDataDownloadedParameters
): void;
function trackEvent(
  name: GTMCustomEvent.ProjectModelDownloaded,
  parameters: ProjectModelDownloadedParameters
): void;
function trackEvent(
  name: GTMCustomEvent.ProjectReset,
  parameters: ProjectResetParameters
): void;
function trackEvent(
  name: GTMCustomEvent.SuggestedAnnotationChanged,
  parameters: SuggestedAnnotationChangedParameters
): void;
function trackEvent(
  name: GTMCustomEvent.SuggestedAnnotationRemoved,
  parameters: SuggestedAnnotationRemovedParameters
): void;
function trackEvent(
  name: GTMCustomEvent,
  parameters: Record<string, number | string> = {}
): void {
  TagManager.dataLayer({ dataLayer: { ...parameters, event: name } });
}
