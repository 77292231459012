import { deliverExportProcessingNotification } from "../../../../utils/deliver-export-processing-notification";
import { RESET_STATE_ACTION_TYPE } from "../../actions/resetState";
import { Project, isExportProcessing } from "../projects";
import {
  NewManualAnnotation,
  State,
  SuggestedAnnotation,
  isSuggestedAnnotation,
} from "./types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: State = { annotations: [], isLocallyModified: false };
let id = 0;

const annotationsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
  },
  name: "annotations",
  initialState,
  reducers: {
    addManual: (
      state,
      {
        payload: { annotation, project },
      }: PayloadAction<{
        annotation: NewManualAnnotation;
        project: Project;
      }>
    ) => {
      if (isExportProcessing(project)) {
        deliverExportProcessingNotification();
        return state;
      }

      return {
        ...state,
        annotations: [
          ...state.annotations,
          { ...annotation, id: `annotations/temporary/${++id}` },
        ],
        isLocallyModified: true,
      };
    },
    addSuggested: (
      state,
      action: PayloadAction<{
        annotations: SuggestedAnnotation[];
      }>
    ) => ({
      ...state,
      annotations: [...state.annotations, ...action.payload.annotations],
      isLocallyModified: false,
    }),
    modifyAnnotationDescription: (
      state,
      {
        payload: { description, id, project },
      }: PayloadAction<{ description: string; id: string; project: Project }>
    ) => {
      if (isExportProcessing(project)) {
        deliverExportProcessingNotification();
        return state;
      }

      return {
        ...state,
        annotations: state.annotations.map((annotation) => {
          if (annotation.id !== id) {
            return annotation;
          }

          return { ...annotation, description };
        }),
        isLocallyModified: true,
      };
    },
    modifyAnnotationWeight: (
      state,
      {
        payload: { id, project, weight },
      }: PayloadAction<{ id: string; project: Project; weight: number }>
    ) => {
      if (isExportProcessing(project)) {
        deliverExportProcessingNotification();
        return state;
      }

      return {
        ...state,
        annotations: state.annotations.map((annotation) => {
          if (annotation.id !== id) {
            return annotation;
          }

          return { ...annotation, weight };
        }),
        isLocallyModified: true,
      };
    },
    modifySuggestedReasoningWeight: (
      state,
      {
        payload: { annotationId, project, suggestionId, weight },
      }: PayloadAction<{
        annotationId: string;
        project: Project;
        suggestionId: string;
        weight: number;
      }>
    ) => {
      if (isExportProcessing(project)) {
        deliverExportProcessingNotification();
        return state;
      }

      return {
        ...state,
        annotations: state.annotations.map((annotation) => {
          if (
            !isSuggestedAnnotation(annotation) ||
            annotation.id !== annotationId
          ) {
            return annotation;
          }

          return {
            ...annotation,
            suggested_reasoning: annotation.suggested_reasoning.map(
              (suggested) => {
                if (suggested.id !== suggestionId) {
                  return suggested;
                }

                return { ...suggested, weight };
              }
            ),
          };
        }),
        isLocallyModified: true,
      };
    },
    remove: (
      state,
      {
        payload: { id, project },
      }: PayloadAction<{ id: string; project: Project }>
    ) => {
      if (isExportProcessing(project)) {
        deliverExportProcessingNotification();
        return state;
      }

      return {
        ...state,
        annotations: state.annotations.filter(
          (annotation) => annotation.id !== id
        ),
        isLocallyModified: true,
      };
    },
    removeAll: () => initialState,
  },
});

export const { name: key, reducer } = annotationsSlice;
export const {
  addManual,
  addSuggested,
  modifyAnnotationDescription,
  modifyAnnotationWeight,
  modifySuggestedReasoningWeight,
  remove,
  removeAll,
} = annotationsSlice.actions;
