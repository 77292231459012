import { resetStateAction } from "../actions/resetState";
import { resetApiState } from "../api/tympana";
import { createListenerMiddleware } from "@reduxjs/toolkit";

const listener = createListenerMiddleware();

listener.startListening({
  actionCreator: resetStateAction,
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(resetApiState());
  },
});

export const { middleware } = listener;
