import { isLocalAuthentication } from "../utils/environment";
import { CognitoAuthenticator } from "./cognito-auth";
import { LocalAuthenticator } from "./local-auth";
import { FC, ReactNode } from "react";

interface UserFacade {
  id: string;
  token: string;
  username: string;
}

interface UseAuthAuthenticatedState {
  isAuthenticated: true;
  signOut: () => void;
  user: UserFacade;
}

interface UseAuthUnauthenticatedState {
  isAuthenticated: false;
  signOut: () => void;
  user: undefined;
}

type UseAuthFacade = UseAuthAuthenticatedState | UseAuthUnauthenticatedState;

export interface IAuthenticator {
  AuthenticationProvider: FC<{ children: ReactNode }>;
  Authenticator: FC;
  useAuth: () => UseAuthFacade;
}

const authenticator: IAuthenticator = isLocalAuthentication
  ? LocalAuthenticator
  : CognitoAuthenticator;

export const Authenticator: FC = () => {
  return authenticator.Authenticator({});
};

export const AuthenticationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return authenticator.AuthenticationProvider({ children });
};

export function useAuth(): UseAuthFacade {
  return authenticator.useAuth();
}
