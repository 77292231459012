import { TYMPANA_API, isTest } from "../../../../utils/environment";
import {
  addSuggested,
  removeAll as removeAllAnnotations,
} from "../../features/annotations";
import { AuthState, key as authKey } from "../../features/auth";
import {
  Project,
  synchronizeProject,
  synchronizeProjects,
} from "../../features/projects";
import {
  tympanaAnnotationToAnnotation,
  tympanaProjectToProject,
} from "./transforms";
import {
  AnnotateRequestBody,
  ExportAnnotationDataResponseBody,
  ExportModelResponseBody,
  GetNextPointResponseBody,
  GetProjectResponseBody,
  GetProjectsResponseBody,
  ResetProjectResponseBody,
} from "./types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { z } from "zod";

export const key = "tympanaApi";

/**
 * This takes a path and optional query parameters to generate a URI-compliant
 * string.
 */
function generatePath(
  path: string,
  {
    host = TYMPANA_API,
    queryParams = {},
  }: {
    host?: string;
    queryParams?: Record<string, string>;
  }
): string {
  const url = new URL(path, host);

  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.set(key, value);
  }

  return url.href.replace(host, "");
}

const tympanaApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: TYMPANA_API,
    prepareHeaders: (headers, { getState }) => {
      const auth = (getState() as { [authKey]: AuthState })[authKey];
      if (auth.isAuthenticated) {
        headers.set("authorization", `Bearer ${auth.user.bearerToken}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    annotate: builder.mutation<unknown, z.infer<typeof AnnotateRequestBody>>({
      invalidatesTags: ["Annotation", "Project"],
      query: ({ annotation, instanceID, taskID, userID }) => ({
        method: "POST",
        body: { annotation, instanceID, taskID, userID },
        url: "/annotate",
      }),
    }),

    exportAnnotationData: builder.mutation<
      z.infer<typeof ExportAnnotationDataResponseBody>,
      { projectId: string; userId: string }
    >({
      query: ({ projectId: taskID, userId: userID }) => {
        return {
          method: "GET",
          url: generatePath(`export_data`, {
            queryParams: { taskID, userID },
          }),
        };
      },
      transformResponse: (response) =>
        ExportAnnotationDataResponseBody.parse(response),
    }),

    exportModel: builder.mutation<
      z.infer<typeof ExportModelResponseBody>,
      { projectId: string; userId: string }
    >({
      invalidatesTags: ["Project"],
      query: ({ projectId, userId }) => ({
        method: "GET",
        url: generatePath("export_model", {
          queryParams: { taskID: projectId, userID: userId },
        }),
      }),
      transformResponse: (response) => ExportModelResponseBody.parse(response),
    }),

    getNextPoint: builder.query<
      z.infer<typeof GetNextPointResponseBody>,
      { projectId: string; projectType: Project["type"]; userId: string }
    >({
      onQueryStarted: async ({ projectType }, { dispatch, queryFulfilled }) => {
        dispatch(removeAllAnnotations());
        const { data: dataInstance } = await queryFulfilled;
        dispatch(
          addSuggested({
            annotations: dataInstance.suggested_annotations.map((a) =>
              tympanaAnnotationToAnnotation(projectType, {
                ...a,
                source: "suggested",
              })
            ),
          })
        );
      },
      providesTags: ["Annotation"],
      query: ({ projectId, userId }) => {
        return {
          method: "GET",
          url: generatePath("get_next_point", {
            queryParams: { projectID: projectId, userID: userId },
          }),
        };
      },
      transformResponse: (response) => GetNextPointResponseBody.parse(response),
    }),

    getProject: builder.query<
      z.infer<typeof GetProjectResponseBody>,
      { projectId: string; userId: string }
    >({
      onQueryStarted: async (_queryArg, { dispatch, queryFulfilled }) => {
        const { data: project } = await queryFulfilled;
        dispatch(synchronizeProject(tympanaProjectToProject(project)));
      },
      providesTags: (result, _error, queryArgs) =>
        result ? [{ type: "Project" as const, id: queryArgs.projectId }] : [],
      query: ({ projectId, userId }) => {
        return {
          method: "GET",
          url: generatePath("get_project", {
            queryParams: { projectID: projectId, userID: userId },
          }),
        };
      },
      transformResponse: (response) => GetProjectResponseBody.parse(response),
    }),

    getProjects: builder.query<
      z.infer<typeof GetProjectsResponseBody>["project_list"],
      { userId: string }
    >({
      onQueryStarted: async (_queryArg, { dispatch, queryFulfilled }) => {
        const { data: projects } = await queryFulfilled;
        dispatch(
          synchronizeProjects(projects.map((p) => tympanaProjectToProject(p)))
        );
      },
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Project" as const, id }))]
          : ["Project"],
      query: ({ userId }) => {
        const url = new URL(`${TYMPANA_API}/get_projects`);
        url.searchParams.set("userID", userId);
        return {
          method: "GET",
          url: generatePath("get_projects", {
            queryParams: { userID: userId },
          }),
        };
      },
      transformResponse: (response) =>
        GetProjectsResponseBody.parse(response).project_list,
    }),

    resetProject: builder.mutation<
      z.infer<typeof ResetProjectResponseBody>,
      { projectId: string; userId: string }
    >({
      invalidatesTags: ["Annotation", "Project"],
      query: ({ projectId, userId }) => {
        return {
          method: "GET",
          url: generatePath(`reset`, {
            queryParams: { userID: userId, taskID: projectId },
          }),
        };
      },
      transformResponse: (response) => ResetProjectResponseBody.parse(response),
    }),
  }),
  keepUnusedDataFor: isTest ? 0 : 60,
  reducerPath: key,
  tagTypes: ["Annotation", "Project"],
});

export const { middleware, reducer } = tympanaApi;

export const {
  useAnnotateMutation,
  useExportAnnotationDataMutation,
  useExportModelMutation,
  useGetNextPointQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useResetProjectMutation,
} = tympanaApi;

export const { resetApiState } = tympanaApi.util;
