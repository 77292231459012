import styled from "styled-components";

const Row = styled.div`
  margin: 0 auto;
  max-width: 75rem;
  padding: 2rem 1rem;
  width: 100%;
  @media (min-width: 40em) {
    padding: 3rem 2rem;
  }
`;

export default Row;
