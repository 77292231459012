import App from "./App";
import AppProviders from "./components/AppProviders";
import reportWebVitals from "./reportWebVitals";
import "./styles/_normalize.css";
import "./styles/base.css";
import { initialize as initializeGoogleTagManager } from "./utils/google-tag-manager";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

initializeGoogleTagManager();

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
