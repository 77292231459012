import { SequenceSuggestionRange } from "../../../app/redux/features/annotations";
import { FC } from "react";
import styled, { css, keyframes } from "styled-components";

interface RangeProps {
  "data-suggested-annotation-weight": number;
}

const enterRange = keyframes`
  from {
    transform: translateY(-50%) scale(1, 0);
  }
`;

const Range = styled.span<RangeProps>`
  position: relative;
  &::before {
    animation: ${enterRange} 1000ms ${(props) => props.theme.ease.quintOut};
    background: rgba(20, 49, 98, 0.33);
    bottom: 10px; /* magic number */
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 20px; /* magic number */
    transform: translateY(-50%)
      scale(1, ${(props) => props["data-suggested-annotation-weight"] * 0.5});
    transform-origin: bottom center;
    z-index: -1;

    ${(props) =>
      props["data-suggested-annotation-weight"] < 0 &&
      css`
        background: rgba(255, 160, 129, 0.5);
      `}
  }
`;

const RangeWrap = styled.span`
  display: inline-flex;
`;

interface WeightedSequenceDataSignature {
  data: string;
  label?: string;
  ranges?: SequenceSuggestionRange[];
}

const DEFAULT_WEIGHT = 0;

export const WeightedSequenceData: FC<WeightedSequenceDataSignature> = ({
  data,
  label,
  ranges,
}) => {
  let sortedRanges: SequenceSuggestionRange[];

  if (ranges) {
    sortedRanges = [...ranges];
    sortedRanges.sort((a, b) => a.start.x - b.start.x);
  } else {
    sortedRanges = [
      {
        end: { x: data.length, y: 0 },
        start: { x: 1, y: 0 },
        weight: DEFAULT_WEIGHT,
      },
    ];
  }

  return (
    <RangeWrap aria-label={label}>
      {sortedRanges.map((range) => (
        <Range
          data-suggested-annotation-weight={range.weight}
          key={range.start.x}
        >
          {data.substring(range.start.x - 1, range.end.x)}
        </Range>
      ))}
    </RangeWrap>
  );
};
