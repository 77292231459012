import { RESET_STATE_ACTION_TYPE } from "../../actions/resetState";
import { AuthState, SignInArgs } from "./types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: AuthState = { isAuthenticated: false };

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE_ACTION_TYPE, () => initialState);
  },
  name: "auth",
  initialState: initialState as AuthState,
  reducers: {
    signIn: (_state, action: PayloadAction<SignInArgs>) => ({
      isAuthenticated: true,
      user: {
        bearerToken: action.payload.bearerToken,
        id: action.payload.id,
        username: action.payload.username,
      },
    }),
    signOut: () => initialState,
  },
});

export const { name: key, reducer } = authSlice;
export const { signIn, signOut } = authSlice.actions;
