import { isUndefined } from "../../../../utils/assertions";
import { DataInstance, DataInstanceTransientParams } from "../types";
import { Factory } from "fishery";

export function generateImageData(sequence: number): string {
  const image = `
    <svg version="1.1" width="620" height="480" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#cccccc" />
      <text x="310" y="240" font-size="60" text-anchor="middle" fill="#969696">620 x 480 ${sequence}</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(image)}`;
}

function generateSequenceData(sequence: number): string {
  return `${sequence}MKAVITLLFLACILVVTYGDLICGTNYCKDHPCTSPIARASCRSPATYRANHSGKCACCPACVTLLRERA`;
}

function generateSampleData({
  project,
  sequence = 0,
}: {
  project?: DataInstanceTransientParams["project"];
  sequence?: number;
}): Pick<DataInstance, "data_repr" | "repr_format"> {
  if (isUndefined(project)) {
    return {
      data_repr: generateSequenceData(sequence),
      repr_format: "sequence",
    };
  }

  switch (project.type) {
    case "image":
    case "sensor":
      return { data_repr: generateImageData(sequence), repr_format: "base64" };
    case "sequence":
      return {
        data_repr: generateSequenceData(sequence),
        repr_format: "sequence",
      };
  }
}

class DataInstanceFactory extends Factory<
  DataInstance,
  DataInstanceTransientParams
> {
  edited() {
    return this.params({ status: "edited" });
  }

  unedited() {
    return this.params({ status: "unedited" });
  }
}

export const factory = DataInstanceFactory.define(
  ({ sequence, transientParams }) => ({
    ...generateSampleData({ project: transientParams.project, sequence }),
    id: `data-instance-${sequence}`,
    meta_data: "",
    status: "unedited" as const,
    taskId: transientParams.project?.id || "1",
  })
);
