import { isBiologicalSequence, isImage, isSensor } from "../app/models/project";
import {
  useAnnotateMutation,
  useGetNextPointQuery,
  useGetProjectQuery,
} from "../app/redux/api/tympana";
import { annotationToTympanaAnnotation } from "../app/redux/api/tympana/transforms";
import {
  Annotation,
  NewManualAnnotation,
  SuggestedAnnotation,
  addManual,
  isManualAnnotation,
  isSuggestedAnnotation,
  isSuggestedImageAnnotation,
  isSuggestedSequenceAnnotation,
  modifyAnnotationDescription,
  modifyAnnotationWeight,
  remove,
  selectAllAnnotations,
  selectById,
  selectIsLocallyModified,
} from "../app/redux/features/annotations";
import { selectCurrentUser } from "../app/redux/features/auth";
import {
  isExportProcessing,
  selectProjectByID,
} from "../app/redux/features/projects";
import { useTypedDispatch, useTypedSelector } from "../app/redux/store";
import { AnnotationRow } from "../components/AnnotationRow";
import Button from "../components/Button";
import Content from "../components/Content";
import { ExportButton } from "../components/ExportButton";
import Header from "../components/Header";
import Icon from "../components/Icon";
import Loading from "../components/Loading";
import Nav from "../components/Nav";
import Progress from "../components/Progress";
import Row from "../components/Row";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import Text from "../components/Text";
import {
  BiologicalSequence,
  Image,
} from "../components/annotation-visualizations";
import { assertIsDefined, isUndefined } from "../utils/assertions";
import { deliverExportProcessingNotification } from "../utils/deliver-export-processing-notification";
import {
  annotationsPersisted,
  manualAnnotationCreated,
  suggestedAnnotationRemoved,
} from "../utils/google-tag-manager";
import { humanizeCoordinate } from "../utils/humanize-coordinate";
import { FC, MouseEventHandler, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Prompt, Redirect, useParams } from "react-router-dom";

const Annotations: FC = () => {
  const dispatch = useTypedDispatch();
  const intl = useIntl();
  const currentUser = useTypedSelector(selectCurrentUser);
  const isLocallyModified = useTypedSelector(selectIsLocallyModified);
  const { projectId } = useParams<{ projectId: string }>();
  const getProjectQueryData = {
    projectId: projectId || "projectId",
    userId: currentUser?.id || "userId",
  };
  const { isError: isGetProjectError, isLoading: isGetProjectLoading } =
    useGetProjectQuery(getProjectQueryData, {
      skip: isUndefined(currentUser) || isUndefined(projectId),
    });
  const project = useTypedSelector((state) =>
    selectProjectByID(state, assertIsDefined(projectId))
  );
  const getNextPointQueryData = {
    projectId: project?.id || "projectId",
    projectType: project?.type || "image",
    userId: currentUser?.id || "userId",
  };
  const {
    data,
    isError: isGetNextPointError,
    isLoading: isGetNextPointLoading,
    isSuccess: isGetNextPointSuccess,
  } = useGetNextPointQuery(getNextPointQueryData, {
    skip: isUndefined(currentUser) || isUndefined(project),
  });
  const [
    annotateTrigger,
    { isError: isAnnotateError, isLoading: isAnnotateLoading },
  ] = useAnnotateMutation();
  const annotations = useTypedSelector(selectAllAnnotations);
  const [highlightedAnnotationId, setHighlightedAnnotationId] =
    useState<string>();
  const [expandedAnnotationId, setExpandedAnnotationId] = useState<string>();

  const expandedAnnotation = useTypedSelector((state) =>
    selectById(state, expandedAnnotationId)
  );

  const sortedAnnotations = useMemo(
    () =>
      [...annotations].sort(
        ({ end: aEnd, start: aStart }, { end: bEnd, start: bStart }) =>
          aStart.x - bStart.x ||
          aEnd.x - bEnd.x ||
          aStart.y - bStart.y ||
          aEnd.y - bEnd.y
      ),
    [annotations]
  );

  useEffect(() => {
    if (!highlightedAnnotationId) {
      return;
    }

    const annotationRow = document.querySelector('tr[aria-selected="true"]');

    if (annotationRow) {
      annotationRow.scrollIntoView({ behavior: "smooth" });
    }
  }, [highlightedAnnotationId]);

  if (isUndefined(project) || isUndefined(data)) {
    return <Loading />;
  }

  if (isUndefined(currentUser)) {
    return <Redirect to="/dashboard" />;
  }

  const saveAndLoadNextDataInstance: MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    if (isUndefined(data) || isUndefined(projectId)) {
      throw new Error(`Cannot save annotations due to incomplete data`);
    }

    if (isExportProcessing(project.toData())) {
      deliverExportProcessingNotification();
      return;
    }

    if (
      annotations.length === 0 &&
      !window.confirm(
        intl.formatMessage({
          id: "You have not identified any annotations for this data instance. You cannot return to this instance once you proceed. Are you sure that you'd like to persist no annotations and proceed to the next data set?",
        })
      )
    ) {
      return;
    }

    await annotateTrigger({
      annotation: annotations.map((a) =>
        isManualAnnotation(a)
          ? annotationToTympanaAnnotation(project.type, a)
          : annotationToTympanaAnnotation(project.type, a)
      ),
      instanceID: data.instance_id,
      taskID: projectId,
      userID: currentUser.id,
    });
    annotationsPersisted(projectId);
  };

  const addManualAnnotation = (annotation: NewManualAnnotation): void => {
    dispatch(addManual({ annotation, project: project.toData() }));
    manualAnnotationCreated({
      annotationDescription: annotation.description,
      annotationWeight: annotation.weight,
      projectId: projectId,
    });
  };

  const navigateToAnnotation = (annotation: Annotation): void => {
    setHighlightedAnnotationId(annotation.id);
  };

  const isError = isGetProjectError || isGetNextPointError || isAnnotateError;
  const isLoading =
    isGetProjectLoading || isGetNextPointLoading || isAnnotateLoading;

  const removeAnnotation = (annotation: Annotation) => {
    if (isSuggestedAnnotation(annotation)) {
      suggestedAnnotationRemoved({
        annotationDescription: annotation.description,
        annotationId: annotation.id,
        annotationWeight: annotation.weight,
        projectId: project.id,
      });
    }

    dispatch(remove({ id: annotation.id, project: project.toData() }));
  };

  const onDescriptionChange = (annotation: Annotation, description: string) => {
    dispatch(
      modifyAnnotationDescription({
        description,
        id: annotation.id,
        project: project.toData(),
      })
    );
  };

  const onWeightChange = (annotation: Annotation, weight: number): void => {
    dispatch(
      modifyAnnotationWeight({
        id: annotation.id,
        project: project.toData(),
        weight,
      })
    );
  };

  const onToggleExpandedAnnotation = (
    annotation: SuggestedAnnotation
  ): void => {
    setExpandedAnnotationId(
      expandedAnnotationId === annotation.id ? undefined : annotation.id
    );
  };

  return (
    <>
      <Prompt
        when={isLocallyModified}
        message={intl.formatMessage({
          id: "Your annotations have not been saved. Navigating away may risk losing work. Are you sure that you'd like to leave this page and possibly lose unsaved annotations?",
        })}
      />
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: "Annotate {name} – Tympana" },
            { name: project.name }
          )}
        </title>
      </Helmet>
      <Nav />
      <Header.Container $hasMinimap={isBiologicalSequence(project)}>
        <Row>
          <Header.Content>
            <div>
              <Header.Back to="/dashboard">
                <Icon id="arrowLeft" />
                  Back
              </Header.Back>
              <Text.H1>{project.name}</Text.H1>
            </div>
            <Header.Actions>
              <div>
                <Text.H3 as="h2">Progress</Text.H3>
                <Progress
                  $count={`${project.progress.annotationsDone} / ${project.progress.totalInstances}`}
                  $invert={true}
                  $progress={project.progressPercentage}
                />
              </div>
              <div>
                <Text.H3 as="h2">Confidence</Text.H3>
                <Progress
                  $export={project.confidence.exportThreshold * 100}
                  $invert={true}
                  $progress={project.confidence.value}
                />
              </div>
              <ExportButton project={project} variant="white" />
            </Header.Actions>
          </Header.Content>
        </Row>
      </Header.Container>

      <Content>
        {isBiologicalSequence(project) && (
          <BiologicalSequence
            addAnnotation={addManualAnnotation}
            annotations={sortedAnnotations}
            annotationTypes={project.annotationTypes}
            changeDescription={onDescriptionChange}
            changeWeight={onWeightChange}
            data={data.data_repr}
            explainedAnnotation={
              expandedAnnotation &&
              isSuggestedSequenceAnnotation(expandedAnnotation)
                ? expandedAnnotation
                : undefined
            }
            explainedAnnotationLabel={
              expandedAnnotation &&
              intl.formatMessage(
                { id: "Visual explanation of suggested {name} at {location}" },
                {
                  location: humanizeCoordinate(
                    project.type,
                    expandedAnnotation.start
                  ),
                  name: expandedAnnotation.description,
                }
              )
            }
            onSelectAnnotation={navigateToAnnotation}
            removeAnnotation={removeAnnotation}
          />
        )}

        {isImage(project) && isGetNextPointSuccess && (
          <Image
            addAnnotation={addManualAnnotation}
            annotations={sortedAnnotations}
            annotationTypes={project.annotationTypes}
            changeDescription={onDescriptionChange}
            changeWeight={onWeightChange}
            data={data.data_repr}
            explainedAnnotation={
              expandedAnnotation &&
              isSuggestedImageAnnotation(expandedAnnotation)
                ? expandedAnnotation
                : undefined
            }
            explainedAnnotationLabel={
              expandedAnnotation &&
              intl.formatMessage(
                { id: "Visual explanation of suggested {name} at {location}" },
                {
                  location: humanizeCoordinate(
                    project.type,
                    expandedAnnotation.start
                  ),
                  name: expandedAnnotation.description,
                }
              )
            }
            onSelectAnnotation={navigateToAnnotation}
            removeAnnotation={removeAnnotation}
          />
        )}

        {isSensor(project) && isGetNextPointSuccess && (
          <Image
            addAnnotation={addManualAnnotation}
            annotations={sortedAnnotations}
            annotationTypes={project.annotationTypes}
            axes={data.axes}
            changeDescription={onDescriptionChange}
            changeWeight={onWeightChange}
            data={data.data_repr}
            explainedAnnotation={
              expandedAnnotation &&
              isSuggestedImageAnnotation(expandedAnnotation)
                ? expandedAnnotation
                : undefined
            }
            onSelectAnnotation={navigateToAnnotation}
            removeAnnotation={removeAnnotation}
          />
        )}

        <Row>
          <Table.Header>
            <Text.H2>Annotations</Text.H2>
            {isGetNextPointSuccess && (
              <div>
                <Button
                  disabled={isAnnotateLoading}
                  onClick={saveAndLoadNextDataInstance}
                >
                  Next Data Instance
                </Button>
              </div>
            )}
          </Table.Header>

          <Table.Overflow>
            <Table.Container>
              <Table.THead>
                <tr>
                  <Table.TH>Weight</Table.TH>
                  <Table.TH>Source</Table.TH>
                  <Table.TH>Description</Table.TH>
                  <Table.TH>Start</Table.TH>
                  <Table.TH>End</Table.TH>
                  <Table.TH>
                    <span className="srt">Actions</span>
                  </Table.TH>
                </tr>
              </Table.THead>

              <Table.TBody>
                {isLoading && (
                  <tr>
                    <Table.TD colSpan={6} $state="loading">
                      <Spinner />
                        
                      <FormattedMessage id="Loading Annotations" />
                    </Table.TD>
                  </tr>
                )}
                {isError && (
                  <tr>
                    <Table.TD colSpan={6} $state="error">
                      <FormattedMessage id="There was an error loading annotations" />
                    </Table.TD>
                  </tr>
                )}
                {isGetNextPointSuccess &&
                  sortedAnnotations.map((annotation) => (
                    <AnnotationRow
                      annotation={annotation}
                      changeDescription={onDescriptionChange}
                      changeWeight={onWeightChange}
                      key={annotation.id}
                      isDisplayingReasoning={
                        isSuggestedAnnotation(annotation) &&
                        expandedAnnotation === annotation
                      }
                      isHighlighted={highlightedAnnotationId === annotation.id}
                      removeAnnotation={() => removeAnnotation(annotation)}
                      project={project}
                      toggleIsDisplayingReasoning={() => {
                        isSuggestedAnnotation(annotation) &&
                          onToggleExpandedAnnotation(annotation);
                      }}
                    />
                  ))}
                {isGetNextPointSuccess && annotations.length === 0 && (
                  <tr>
                    <Table.TD colSpan={6} $state="empty">
                      <FormattedMessage id="Add annotations to manage them here" />
                    </Table.TD>
                  </tr>
                )}
              </Table.TBody>
            </Table.Container>
          </Table.Overflow>
        </Row>
      </Content>
    </>
  );
};

export default Annotations;
