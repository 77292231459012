import { FC } from "react";
import { useIntl } from "react-intl";
import styled, { css, keyframes } from "styled-components";

const Bar = styled.div`
  background: ${(props) => props.theme.color.grayT};
  height: 1rem;
  position: relative;
  width: 100%;
`;

interface BarExportProps {
  $export: number;
}

const BarExport = styled.span<BarExportProps>`
  border-left: 1px solid ${(props) => props.theme.color.blueSS};
  font-size: ${(props) => props.theme.size.down2};
  height: 2.375rem;
  left: ${(props) => props.$export}%;
  position: absolute;
  top: 0rem;

  span {
    bottom: -0.125rem;
    left: 0.5rem;
    line-height: 1rem;
    position: absolute;
  }

  ${(props) =>
    props.$export > 50 &&
    css`
      span {
        left: auto;
        right: 0.5rem;
        text-align: right;
      }
    `}
`;

const BarLabel = styled.span`
  left: 100%;
  line-height: 1rem;
  padding-left: 0.5rem;
  position: absolute;
  top: 0;
`;

interface BarProgressProps {
  $progress: number;
}

const enterBarProgress = keyframes`
  from {
    transform: scale(0, 1);
  }
`;

const BarProgress = styled.div<BarProgressProps>`
  animation: ${enterBarProgress} 1500ms ${(props) => props.theme.ease.quintOut}
    150ms both;
  background: ${(props) => props.theme.color.mint};
  height: 100%;
  transform-origin: left center;

  ${(props) =>
    css`
      width: ${props.$progress * 100}%;
    `}
`;

interface ContainerProps {
  $invert?: boolean;
}

const Container = styled.div<ContainerProps>`
  min-width: 8rem;
  padding: 0 3rem 1.5rem 0;
  position: relative;
  width: 100%;

  ${(props) =>
    props.$invert &&
    css`
      ${Bar} {
        background: ${(props) => props.theme.color.blue};
      }
      ${BarExport} {
        border-color: ${(props) => props.theme.color.white};
      }
    `}
`;

const Count = styled.span`
  bottom: 0;
  font-size: ${(props) => props.theme.size.down2};
  left: 0;
  line-height: 1rem;
  position: absolute;
`;

interface ProgressProps {
  $count?: string;
  $export?: number;
  $invert?: boolean;
  $progress: number;
}

const Progress: FC<ProgressProps> = ({
  $count,
  $export,
  $invert,
  $progress,
}) => {
  const intl = useIntl();

  return (
    <Container $invert={$invert}>
      <Bar>
        <BarProgress $progress={$progress} />
        <BarLabel>
          {intl.formatMessage(
            { id: "{percentage}" },
            { percentage: $progress }
          )}
        </BarLabel>
        {$export && (
          <BarExport $export={$export}>
            <span>Export</span>
            <span className="srt">
              {intl.formatMessage(
                { id: "At {threshold}" },
                { threshold: $export }
              )}
            </span>
          </BarExport>
        )}
      </Bar>

      {$count && <Count>{$count}</Count>}
    </Container>
  );
};

export default Progress;
