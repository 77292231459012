import {
  Annotation,
  AnnotationTransientParams,
  DataInstance,
  SuggestedAnnotation,
} from "../types";
import { Factory } from "fishery";

export function generateSuggestedReasoningImageData(sequence: number): string {
  const image = `
    <svg version="1.1" width="620" height="480" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#cccccc" />
      <text x="310" y="240" font-size="60" text-anchor="middle" fill="#969696">620 x 480 ${sequence}</text>
      <text x="310" y="280" font-size="30" text-anchor="middle" fill="#969696">Suggested Reasoning</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(image)}`;
}

export function generateSuggestedReasoningSequenceData(): string {
  return JSON.stringify([
    { end: { x: 1, y: 0 }, start: { x: 0, y: 0 }, weight: 1 },
  ]);
}

function generatedSuggestionData(
  sequence: number,
  type?: DataInstance["repr_format"]
): Pick<SuggestedAnnotation, "data_repr" | "repr_format"> {
  switch (type) {
    case "sequence":
      return {
        data_repr: generateSuggestedReasoningSequenceData(),
        repr_format: "json",
      };
    default:
      return {
        data_repr: generateSuggestedReasoningImageData(sequence),
        repr_format: "base64",
      };
  }
}

class AnnotationFactory extends Factory<Annotation, AnnotationTransientParams> {
  manual() {
    return this.params({
      source: "manual",
    });
  }

  suggested() {
    const sequence = this.sequence();

    return this.params({
      id: `suggested-annotation-${sequence}`,
      source: "suggested",
      suggested_reasoning: [
        {
          description: `Suggested Description ${sequence}`,
          end: { x: 2, y: 0 },
          id: `suggested-annotation-${sequence}`,
          start: { x: 1, y: 0 },
          weight: 3,
        },
      ],
    });
  }
}

export const factory = AnnotationFactory.define(
  ({ sequence, transientParams }) => ({
    ...generatedSuggestionData(
      sequence,
      transientParams.dataInstance?.repr_format
    ),
    description: `Description ${sequence}`,
    end: { x: 3, y: 0 },
    instanceId: transientParams.dataInstance?.id || "data-instance-1",
    taskId: transientParams.dataInstance?.taskId || "task-1",
    source: "manual" as const,
    start: { x: 1, y: 0 },
    weight: 3,
  })
);
