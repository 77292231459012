import styled from "styled-components";

const H1 = styled.h1`
  font-size: ${(props) => props.theme.size.up5};
  font-weight: bold;
  line-height: 3.5rem;
`;

const H2 = styled.h2`
  font-size: ${(props) => props.theme.size.up3};
  font-weight: bold;
  line-height: 2.5rem;
`;

const H3 = styled.h3`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Text = { H1, H2, H3 };

export default Text;
