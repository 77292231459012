import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  min-height: 100vh;
  padding: 2rem;
`;

const Content = styled.p`
  margin: auto;
  text-align: center;
`;

const Error: FC = () => {
  return (
    <Container>
      <Content>
        <FormattedMessage id="There was an error loading exports." />{" "}
        <Link to="/dashboard">
          <FormattedMessage id="Head back to the dashboard" />
        </Link>
        .
      </Content>
    </Container>
  );
};

export default Error;
