import styled, { keyframes } from "styled-components";

const enterContent = keyframes`
  from {
    opacity: 0;
  }
`;

const Content = styled.div`
  animation: ${enterContent} 750ms ease 50ms both;
  position: relative;
  z-index: 10;
`;

export default Content;
