import { useAuth } from "../contexts/auth";
import tympanaLogo from "../logos/tympana-dark.svg";
import { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

const Actions = styled.div`
  display: flex;
  a {
    display: block;
    padding: 1rem;
    text-decoration: none;
  }
  .active {
    font-weight: bold;
  }
`;

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  @media (min-width: 40em) {
    padding: 0 1rem;
  }
`;

const Logo = styled(Link)`
  display: block;
  padding: 0.75rem 1rem;
  img {
    display: block;
    height: 2rem;
    width: auto;
  }
`;

const Nav: FC = () => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Logo to="/dashboard">
        <img src={tympanaLogo} alt="Tympana" />
      </Logo>
      <Actions>
        <NavLink to="/dashboard">Projects</NavLink>
        <Link onClick={signOut} to="/">
          Sign Out
        </Link>
      </Actions>
    </Container>
  );
};

export default Nav;
