function readConfiguration(name: string, fallback?: string): string {
  const key = `REACT_APP_${name}`;
  const value = process.env[key];

  if (value === undefined) {
    if (fallback !== undefined) {
      return fallback;
    }
    throw new Error(`Required process.env.${key} is unset`);
  }

  return value;
}

/**
 * Returns `true` when the application is built and running in "development"
 * mode.
 */
export const isDevelopment = process.env.NODE_ENV === "development";

/**
 * Returns `true` when the application is built and running in "production"
 * mode.
 */
export const isProduction = process.env.NODE_ENV === "production";

/**
 * Returns `true` when the application is built and running in "test" mode.
 */
export const isTest = process.env.NODE_ENV === "test";

export const AWS_COGNITO_REGION = readConfiguration("AWS_COGNITO_REGION");
export const AWS_USER_POOLS_ID = readConfiguration("AWS_USER_POOLS_ID");
export const AWS_USER_POOLS_WEB_CLIENT_ID = readConfiguration(
  "AWS_USER_POOLS_WEB_CLIENT_ID"
);

export const AUTHENTICATION_METHOD = readConfiguration(
  "AUTHENTICATION_METHOD",
  "cognito"
);

export const isLocalAuthentication =
  isTest || AUTHENTICATION_METHOD === "local";

export const TYMPANA_API = readConfiguration(
  "TYMPANA_API",
  "https://tympana.url"
);

export const GTM_CONTAINER_ID =
  readConfiguration("GTM_CONTAINER_ID", "") || undefined;
export const GTM_CONTAINER_AUTH =
  readConfiguration("GTM_CONTAINER_AUTH", "") || undefined;
export const GTM_CONTAINER_PREVIEW =
  readConfiguration("GTM_CONTAINER_PREVIEW", "") || undefined;
