import { Project as ProjectData } from "../redux/features/projects";

export function isBiologicalSequence(project: Project): boolean {
  return project.type === "sequence";
}

export function isImage(project: Project): boolean {
  return project.type === "image";
}

export function isSensor(project: Project): boolean {
  return project.type === "sensor";
}

export class Project {
  constructor(private readonly _data: ProjectData) {}

  get annotationTypes(): string[] {
    return this._data.annotation_types;
  }

  get confidence() {
    return {
      estimatedRemainingSamples:
        this._data.confidence.estimated_remaining_samples,
      exportThreshold: this._data.confidence.export_threshold,
      value: this._data.confidence.value,
    };
  }

  get id(): string {
    return this._data.id;
  }

  get isExportable(): boolean {
    return this.confidence.value >= this.confidence.exportThreshold;
  }

  get modelExport() {
    return this._data.model_export;
  }

  get name(): string {
    return this._data.name;
  }

  get progress() {
    return {
      annotationsDone: this._data.progress.annotations_done,
      totalInstances: this._data.progress.total_instances,
    };
  }

  get progressPercentage(): number {
    if (this.progress.totalInstances === 0) {
      return 0;
    }

    return this.progress.annotationsDone / this.progress.totalInstances;
  }

  toData(): ProjectData {
    return { ...this._data };
  }

  get type() {
    return this._data.type;
  }

  get updatedAt() {
    return this._data.updated_at ? new Date(this._data.updated_at) : undefined;
  }
}
