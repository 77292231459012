import { z } from "zod";

const DataURL = z.string().regex(/^data:image\/.+?;base64,/);

const Coordinate = z.object({
  x: z.number().int(),
  y: z.number().int(),
});

const BaseAnnotationObject = z.object({
  description: z.string(),
  end: Coordinate,
  start: Coordinate,
  weight: z.number().gte(1).lte(5).int(),
});

const ManualAnnotationObject = BaseAnnotationObject.extend({
  source: z.literal("manual"),
});

const BaseSuggestedAnnotationObject = BaseAnnotationObject.extend({
  id: z.string(),
  source: z.literal("suggested"),
  suggested_reasoning: z.array(
    z.object({
      description: z.string(),
      end: Coordinate,
      id: z.string(),
      start: Coordinate,
      weight: z.number().gte(1).lte(5).int(),
    })
  ),
});

const SuggestedImageAnnotationObject = BaseSuggestedAnnotationObject.extend({
  data_repr: DataURL,
  repr_format: z.literal("base64"),
});

const SuggestedSequenceAnnotationObject = BaseSuggestedAnnotationObject.extend({
  data_repr: z.string().startsWith("[").endsWith("]"),
  repr_format: z.literal("json"),
});

const SuggestedAnnotationObject = z.discriminatedUnion("repr_format", [
  SuggestedImageAnnotationObject,
  SuggestedSequenceAnnotationObject,
]);

const AnnotationObject = z.union([
  ManualAnnotationObject,
  SuggestedAnnotationObject,
]);

export type ManualAnnotation = z.infer<typeof ManualAnnotationObject>;
export type SuggestedImageAnnotation = z.infer<
  typeof SuggestedImageAnnotationObject
>;
export type SuggestedSequenceAnnotation = z.infer<
  typeof SuggestedSequenceAnnotationObject
>;
export type SuggestedAnnotation = z.infer<typeof SuggestedAnnotationObject>;
export type Annotation = ManualAnnotation | SuggestedAnnotation;

const ProjectObject = z.object({
  annotation_types: z.array(z.string()),
  confidence: z.object({
    estimated_remaining_samples: z.number(),
    export_threshold: z.number().gte(0).lte(1),
    value: z.number().gte(0).lte(1),
  }),
  id: z.string(),
  model_export: z.optional(
    z.discriminatedUnion("status", [
      z.object({
        status: z.literal("processing"),
      }),
      z.object({
        status: z.literal("ready"),
        uri: z.string().url(),
      }),
    ])
  ),
  name: z.string(),
  progress: z.object({
    annotations_done: z.number().int().gte(0),
    total_instances: z.number().int().gte(0),
  }),
  type: z.enum(["image", "sensor", "sequence"]),
  updated_at: z.optional(z.string()),
});

export type Project = z.infer<typeof ProjectObject>;

export const AnnotateRequestBody = z.object({
  annotation: z.array(AnnotationObject),
  instanceID: z.string(),
  taskID: z.string(),
  userID: z.string(),
});

export const ExportAnnotationDataResponseBody = z.object({
  dataset_uri: z.string().url(),
});

export const ExportModelResponseBody = ProjectObject;

const GetNextPointBaseResponseBody = z.object({
  axes: z.optional(
    z.object({
      x: z.object({
        end: z.number().int(),
        start: z.number().int(),
      }),
      y: z.object({
        end: z.number().int(),
        start: z.number().int(),
      }),
    })
  ),
  data_repr: z.string(),
  instance_id: z.string(),
  meta_data: z.optional(z.string()),
  repr_format: z.string(),
  suggested_annotations: z.array(
    z.discriminatedUnion("repr_format", [
      SuggestedImageAnnotationObject.omit({ source: true }),
      SuggestedSequenceAnnotationObject.omit({ source: true }),
    ])
  ),
});
const GetNextPointSequenceResponseBody = GetNextPointBaseResponseBody.extend({
  data_repr: z.string(),
  repr_format: z.literal("sequence"),
});
const GetNextPointBase64ResponseBody = GetNextPointBaseResponseBody.extend({
  data_repr: DataURL,
  repr_format: z.literal("base64"),
});

export const GetNextPointResponseBody = z.discriminatedUnion("repr_format", [
  GetNextPointSequenceResponseBody,
  GetNextPointBase64ResponseBody,
]);

export const GetProjectResponseBody = ProjectObject;

export const GetProjectsResponseBody = z.object({
  project_list: z.array(ProjectObject),
});

export const ResetProjectResponseBody = z.object({
  taskID: z.string(),
});

export function isManualAnnotation(
  annotation: Annotation
): annotation is ManualAnnotation {
  return annotation.source === "manual";
}

export function isSuggestedAnnotation(
  annotation: Annotation
): annotation is SuggestedAnnotation {
  return annotation.source === "suggested";
}

export function isSuggestedImageAnnotation(
  annotation: Annotation
): annotation is SuggestedImageAnnotation {
  return (
    isSuggestedAnnotation(annotation) && annotation.repr_format === "base64"
  );
}

export function isSuggestedSequenceAnnotation(
  annotation: Annotation
): annotation is SuggestedSequenceAnnotation {
  return isSuggestedAnnotation(annotation) && annotation.repr_format === "json";
}
