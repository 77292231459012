import {
  useExportAnnotationDataMutation,
  useExportModelMutation,
  useGetProjectQuery,
} from "../app/redux/api/tympana";
import { selectCurrentUser } from "../app/redux/features/auth";
import { selectProjectByID } from "../app/redux/features/projects";
import { useTypedSelector } from "../app/redux/store";
import Button from "../components/Button";
import Content from "../components/Content";
import Error from "../components/Error";
import Header from "../components/Header";
import Icon from "../components/Icon";
import Loading from "../components/Loading";
import Nav from "../components/Nav";
import Row from "../components/Row";
import Text from "../components/Text";
import motifHelix from "../motifs/motif-helix-grayT.svg";
import { assertIsDefined, isUndefined } from "../utils/assertions";
import {
  projectDataDownloaded,
  projectModelDownloaded,
} from "../utils/google-tag-manager";
import { FC, MouseEventHandler } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, Redirect, useParams } from "react-router-dom";
import styled from "styled-components";

const Card = styled.div`
  background-color: ${(props) => props.theme.color.grayTT};
  grid-column-end: span 1;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  &::after {
    background-image: url(${motifHelix});
    background-size: 30rem;
    content: "";
    height: 30rem;
    position: absolute;
    right: -13.5rem;
    top: -7.5rem;
    width: 30rem;
  }
  @media (min-width: 40em) {
    padding: 3rem 7rem 3rem 3rem;
  }
`;

const CardContent = styled.div`
  position: relative;
  z-index: 10;
  p {
    margin-top: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  @media (min-width: 70em) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LinkButton = Button.withComponent(Link);

const Export: FC = () => {
  const intl = useIntl();
  const currentUser = useTypedSelector(selectCurrentUser);
  const { projectId } = useParams<{ projectId: string }>();
  const getProjectQueryData = {
    projectId: projectId || "projectId",
    userId: currentUser?.id || "userId",
  };
  const { isError: isGetProjectError, isLoading: isGetProjectLoading } =
    useGetProjectQuery(getProjectQueryData, {
      skip: isUndefined(currentUser) || isUndefined(projectId),
    });
  const [exportModelTrigger, { isLoading: isExportModelLoading }] =
    useExportModelMutation();
  const [annotateTrigger, { isLoading: isExportAnnotationDataLoading }] =
    useExportAnnotationDataMutation();
  const project = useTypedSelector((state) =>
    selectProjectByID(state, assertIsDefined(projectId))
  );

  if (isUndefined(project) || isGetProjectLoading) {
    return <Loading />;
  }

  if (isGetProjectError) {
    return <Error />;
  }

  if (isUndefined(currentUser) || !project.isExportable) {
    return <Redirect to="/dashboard" />;
  }

  const onExportDataClick: MouseEventHandler<HTMLButtonElement> = async () => {
    const response = await annotateTrigger({
      projectId: projectId,
      userId: currentUser.id,
    }).unwrap();
    projectDataDownloaded({ projectId });
    window.open(response.dataset_uri, "_blank");
  };

  const onExportModelClick: MouseEventHandler<HTMLButtonElement> = () => {
    exportModelTrigger({ projectId, userId: currentUser.id });
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: "Export {name} – Tympana" },
            { name: project.name }
          )}
        </title>
      </Helmet>
      <Nav />
      <Header.Container>
        <Row>
          <Header.Back to="/dashboard">
            <Icon id="arrowLeft" /> <FormattedMessage id="Back" />
          </Header.Back>
          <Header.Content>
            <Text.H1>
              <FormattedMessage
                id="Export: {name}"
                values={{ name: project.name }}
              />
            </Text.H1>
          </Header.Content>
        </Row>
      </Header.Container>

      <Content>
        <Row>
          <Grid>
            <Card>
              <CardContent>
                <Text.H2>
                  <Icon id="annotatedData" />
                  {"  "}
                  <FormattedMessage id="Annotated Data" />
                </Text.H2>
                <p>
                  <FormattedMessage id="Download the current provided annotations and all predicted annotations." />
                </p>
                <Button
                  disabled={isExportAnnotationDataLoading}
                  onClick={onExportDataClick}
                >
                  <Icon id="download" />
                    
                  <FormattedMessage id="Download Annotated Data" />
                </Button>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Text.H2>
                  <Icon id="dockerInstance" />
                    
                  <FormattedMessage id="Docker Instance" />
                </Text.H2>
                <p>
                  <FormattedMessage id="Download the current trained model as a Docker instance. Your annotation workflow will be suspended until export is complete." />
                </p>
                {(!project.modelExport ||
                  project.modelExport.status === "processing") && (
                  <Button
                    disabled={
                      isExportModelLoading ||
                      project.modelExport?.status === "processing"
                    }
                    onClick={onExportModelClick}
                  >
                    <Icon id="download" />
                      
                    <FormattedMessage id="Download Docker Instance" />
                  </Button>
                )}
                {project.modelExport?.status === "ready" && (
                  <LinkButton
                    onClick={() => {
                      projectModelDownloaded({ projectId: project.id });
                    }}
                    target="_blank"
                    to={{ pathname: project.modelExport.uri }}
                  >
                    <Icon id="download" />
                      
                    <FormattedMessage id="Download Docker Instance" />
                  </LinkButton>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Row>
      </Content>
    </>
  );
};

export default Export;
