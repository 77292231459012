import Button from "../components/Button";
import tympanaLogo from "../logos/tympana-light.svg";
import motifHelix from "../motifs/motif-helix-blueS.svg";
import motifSession1 from "../motifs/motif-session-1.svg";
import motifSession2 from "../motifs/motif-session-2.svg";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const enterContainer = keyframes`
  from {
    opacity: 0;
  }
`;

const enterContainerBefore = keyframes`
  from {
    opacity: 0;
    transform: translate(10%, 0);
  }
`;

const enterContainerAfter = keyframes`
  from {
    opacity: 0;
    transform: translate(-10%, 0);
  }
`;

const Container = styled.div`
  animation: ${enterContainer} 750ms ease;
  background-color: ${(props) => props.theme.color.blueSS};
  background-image: url(${motifHelix}), url(${motifHelix});
  background-position: 40vw -25vw, -25vw calc(100vh - 60vw);
  background-repeat: no-repeat;
  background-size: 80vw auto;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  &::after,
  &::before {
    background-size: cover;
    content: "";
    height: 70vmin;
    position: absolute;
    width: 38.1818vmin;
  }
  &::after {
    animation: ${enterContainerAfter} 1500ms
      ${(props) => props.theme.ease.quintOut};
    background-image: url(${motifSession1});
    left: -2.5vmin;
    top: -15vmin;
  }
  &::before {
    animation: ${enterContainerBefore} 1500ms
      ${(props) => props.theme.ease.quintOut};
    background-image: url(${motifSession2});
    bottom: -2.5vmin;
    right: -2.5vmin;
  }
`;

const Content = styled.div`
  color: ${(props) => props.theme.color.white};
  margin: auto;
  position: relative;
  text-align: center;
  z-index: 10;
`;

const LinkButton = Button.withComponent(Link);

const Logo = styled.img`
  display: block;
  height: auto;
  margin: 0 1rem 4rem;
  width: 16rem;
  @media (min-width: 70em) {
    width: 20rem;
  }
`;

const Home: FC = () => {
  return (
    <Container>
      <Content>
        <Logo src={tympanaLogo} alt="Tympana" />
        <LinkButton to="/dashboard" $variant="white">
          <FormattedMessage id="Log In" />
        </LinkButton>
      </Content>
    </Container>
  );
};

export default Home;
