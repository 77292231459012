import { Project } from "../app/models/project";
import { Annotation } from "../app/redux/features/annotations";
import { humanizeCoordinate } from "../utils/humanize-coordinate";
import Form from "./Form";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export type WeightChangeHandler = (
  annotation: Annotation,
  weight: number
) => void;

interface WeightSignature {
  annotation: Annotation;
  onChange?: WeightChangeHandler;
  projectType: Project["type"];
}

export const Weight: FC<WeightSignature> = ({
  annotation,
  onChange,
  projectType,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label>
        <span className="srt">
          <FormattedMessage
            id="Weight of {name} at {location}"
            values={{
              location: humanizeCoordinate(projectType, annotation.start),
              name: annotation.description,
            }}
          />
        </span>
        <Form.RangeWrap>
          <Form.Range
            defaultValue={annotation.weight}
            min={1}
            max={5}
            onChange={(e) => {
              onChange?.(annotation, parseInt(e.target.value, 10));
            }}
            step={1}
            type="range"
          />
        </Form.RangeWrap>
      </label>
    </form>
  );
};
