import { Project } from "../app/models/project";
import {
  Annotation,
  isSuggestedAnnotation,
} from "../app/redux/features/annotations";
import { humanizeCoordinate } from "../utils/humanize-coordinate";
import { Description } from "./Description";
import Icon from "./Icon";
import { SuggestedReasoningRow } from "./SuggestedReasoningRow";
import Table from "./Table";
import { Weight } from "./Weight";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled, { css } from "styled-components";

const IconButton = styled.button`
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.color.blueSS};
  font-size: ${(props) => props.theme.size.up1};
  height: 2rem;
  justify-content: center;
  transition: color 200ms ease;
  width: 2rem;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.blueT};
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 1rem;
`;

const ToggleButton = styled.button`
  color: ${(props) => props.theme.color.blueSS};
  font-size: ${(props) => props.theme.size.down1};
  margin-left: 0.5rem;
  transition: all 200ms ease;
  white-space: nowrap;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.blueT};
  }
  span {
    display: inline-block;
    text-align: center;
    width: 0.75rem;
  }
`;

interface TRProps {
  $highlight?: boolean;
}

const TR = styled.tr<TRProps>`
  ${(props) =>
    props.$highlight &&
    css`
      background: rgba(102, 221, 192, 0.5);
    `}
`;

const Source: Record<Annotation["source"], string> = {
  manual: "Manual",
  suggested: "Suggested",
};

interface AnnotationRowSignature {
  annotation: Annotation;
  changeDescription: (annotation: Annotation, description: string) => void;
  changeWeight: (annotation: Annotation, weight: number) => void;
  isDisplayingReasoning: boolean;
  isHighlighted: boolean;
  project: Project;
  removeAnnotation: () => void;
  toggleIsDisplayingReasoning: () => void;
}

export const AnnotationRow: FC<AnnotationRowSignature> = ({
  annotation,
  changeDescription,
  changeWeight,
  isDisplayingReasoning,
  isHighlighted,
  project,
  removeAnnotation,
  toggleIsDisplayingReasoning,
}) => {
  const intl = useIntl();

  return (
    <>
      <TR
        aria-selected={isHighlighted}
        data-test-annotation-row
        key={annotation.id}
        $highlight={isHighlighted}
      >
        <Table.TD>
          <Weight
            annotation={annotation}
            onChange={changeWeight}
            projectType={project.type}
          />
        </Table.TD>
        <Table.TD $nowrap>
          <Icon id={annotation.source} />
            
          {Source[annotation.source]}
          {isSuggestedAnnotation(annotation) &&
            annotation.suggested_reasoning.length > 0 && (
              <ToggleButton
                aria-label={intl.formatMessage(
                  {
                    id: isDisplayingReasoning
                      ? "Hide explanation for suggested {name} at {location}"
                      : "Explain suggested {name} at {location}",
                  },
                  {
                    location: humanizeCoordinate(
                      project.type,
                      annotation.start
                    ),
                    name: annotation.description,
                  }
                )}
                onClick={() => {
                  toggleIsDisplayingReasoning();
                }}
              >
                [Explain<span>{!isDisplayingReasoning ? "+" : "-"}</span>]
              </ToggleButton>
            )}
        </Table.TD>
        <Table.TD>
          <Description
            annotation={annotation}
            descriptions={project.annotationTypes}
            onChange={changeDescription}
            projectType={project.type}
          />
        </Table.TD>
        <Table.TD $nowrap>
          {humanizeCoordinate(project.type, annotation.start)}
        </Table.TD>
        <Table.TD>{humanizeCoordinate(project.type, annotation.end)}</Table.TD>
        <Table.TD>
          <Table.Actions>
            <IconButton
              aria-label={intl.formatMessage(
                { id: "Remove {name} at {location}" },
                {
                  location: humanizeCoordinate(project.type, annotation.start),
                  name: annotation.description,
                }
              )}
              onClick={() => {
                removeAnnotation();
              }}
            >
              <span className="srt">
                <FormattedMessage id="Delete Annotation" />
              </span>
              <Icon id="delete" />
            </IconButton>
          </Table.Actions>
        </Table.TD>
      </TR>
      {isSuggestedAnnotation(annotation) && isDisplayingReasoning && (
        <Table.TRExpand>
          <td colSpan={6}>
            <List>
              {annotation.suggested_reasoning.map((suggestedReasoning) => (
                <SuggestedReasoningRow
                  annotation={annotation}
                  key={suggestedReasoning.id}
                  project={project}
                  suggestedReasoning={suggestedReasoning}
                />
              ))}
            </List>
          </td>
        </Table.TRExpand>
      )}
    </>
  );
};
