import styled, { css, keyframes } from "styled-components";

interface ButtonProps {
  $fixed?: boolean;
  $state?: "disabled" | "loading";
  $variant?: "white";
}

const loadingButton = keyframes`
  from {
    opacity: 0.33;
  }
  to {
    opacity: 0.66;
  }
`;

const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.$variant ? props.$variant : "transparent")};
  box-shadow: inset 0 0 0 2px
    ${(props) => (props.$variant ? props.$variant : props.theme.color.blueSS)};
  color: ${(props) => props.theme.color.blueSS};
  display: inline-block;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 200ms ease;
  white-space: nowrap;
  @media (min-width: 40em) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  ${(props) =>
    props.$fixed &&
    css`
      align-items: center;
      display: flex;
      height: 3.5rem;
      justify-content: center;
      padding-bottom: 0;
      padding-top: 0;
      width: 100%;
    `}

  ${(props) =>
    props.$state === "disabled" &&
    css`
      cursor: default;
      opacity: 0.5;
    `}

  ${(props) =>
    props.$state === "loading" &&
    css`
      animation: ${loadingButton} 1000ms ease infinite alternate;
      cursor: default;
      opacity: 0.5;
    `}

  ${(props) =>
    !props.$state &&
    css`
      &:hover,
      &:focus {
        background: transparent;
        box-shadow: inset 0 0 0 2px
          ${props.$variant ? props.$variant : props.theme.color.blueT};
        color: ${props.$variant
          ? props.theme.color.white
          : props.theme.color.blueT};
      }
    `}

  span {
    font-size: ${(props) => props.theme.size.down2};
    font-weight: normal;
    line-height: 1rem;
  }
`;

export default Button;
