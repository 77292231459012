interface BaseAnnotation {
  description: string;
  end: Coordinate;
  instanceId: string;
  start: Coordinate;
  taskId: string;
  weight: number;
}

export type ManualAnnotation = BaseAnnotation & {
  source: "manual";
};

export type SuggestedAnnotation = BaseAnnotation & {
  data_repr: string;
  id: string;
  repr_format: "base64" | "json";
  source: "suggested";
  suggested_reasoning: {
    description: string;
    end: Coordinate;
    id: string;
    start: Coordinate;
    weight: number;
  }[];
};

export type Annotation = ManualAnnotation | SuggestedAnnotation;

export interface AnnotationTransientParams {
  dataInstance: DataInstance;
}

interface Coordinate {
  x: number;
  y: number;
}

export interface DataInstance {
  axes?: {
    x: { end: number; start: number };
    y: { end: number; start: number };
  };
  data_repr: string;
  id: string;
  meta_data: string;
  repr_format: "base64" | "sequence";
  status: "edited" | "unedited";
  taskId: string;
}

export interface DataInstanceTransientParams {
  project: Project;
}

export interface Project {
  annotation_types: string[];
  confidence: {
    value: number;
    export_threshold: number;
    estimated_remaining_samples: number;
  };
  id: string;
  model_export?:
    | {
        status: "processing";
      }
    | {
        status: "ready";
        uri: string;
      };
  name: string;
  progress: {
    annotations_done: number;
    total_instances: number;
  };
  type: "image" | "sensor" | "sequence";
  updated_at?: string;
}

/**
 * Type guard to ensure the given Annotation is a ManualAnnotation.
 */
export function isManualAnnotation(
  annotation: Annotation
): annotation is ManualAnnotation {
  return annotation.source === "manual";
}

/**
 * Type guard to ensure the given Annotation is a SuggestedAnnotation.
 */
export function isSuggestedAnnotation(
  annotation: Annotation
): annotation is SuggestedAnnotation {
  return annotation.source === "suggested";
}

export function isUneditedDataInstance(dataInstance: DataInstance): boolean {
  return dataInstance.status === "unedited";
}
