import styled from "styled-components";

const Label = styled.span`
  display: block;
  font-size: ${(props) => props.theme.size.down2};
  font-weight: bold;
`;

// Range

const Range = styled.input`
  accent-color: ${(props) => props.theme.color.blueT};
  cursor: pointer;
`;

const RangeWrap = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  width: 100%;
`;

// Select

const Select = styled.select`
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid ${(props) => props.theme.color.gray};
  border-radius: 0.2rem;
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  min-width: 100%;
  option {
    min-width: 100%;
  }
`;

const SelectWrap = styled.div`
  display: block;
  position: relative;
  &::after {
    content: "›";
    font-size: 1rem;
    pointer-events: none;
    position: absolute;
    right: 0.75rem;
    top: 0.33rem;
    transform: rotate(90deg);
  }
`;

const Form = { Label, Range, RangeWrap, Select, SelectWrap };

export default Form;
