import { Project } from "../../../app/models/project";
import {
  Annotation,
  NewManualAnnotation,
  SuggestedSequenceAnnotation,
  isManualAnnotation,
  isSuggestedAnnotation,
} from "../../../app/redux/features/annotations";
import {
  assert,
  isUndefined,
  isUndefinedOrNull,
} from "../../../utils/assertions";
import { humanizeCoordinate } from "../../../utils/humanize-coordinate";
import {
  Coordinate,
  isCoordinatePair,
} from "../../../utils/is-coordinate-pair";
import { layerAnnotations } from "../../../utils/layer-annotations";
import {
  getCurrentSelection,
  getSelectionEndOffset,
  getSelectionStartOffset,
} from "../../../utils/selection";
import { ManualAnnotation } from "../ManualAnnotation";
import { UpdateAnnotation } from "../UpdateAnnotation";
import { Form } from "./Form";
import { LayeredAnnotationRange } from "./LayeredAnnotationRange";
import { MinimapAnnotation } from "./MinimapAnnotation";
import { WeightedSequenceData } from "./WeightedSequenceData";
import {
  D3BrushEvent,
  D3ZoomEvent,
  axisTop,
  brushX,
  zoom as d3zoom,
  scaleLinear,
  select,
  zoomIdentity,
  zoomTransform,
} from "d3";
import {
  FC,
  RefObject,
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const BRUSH_MIN = 10;
const CHARACTER_HEIGHT = 100;
const CHARACTER_SPACING = 20;
const CHART_ANNOTATION_HEIGHT = 20;
const CHART_ANNOTATION_PADDING = 5;
const CHART_MARGIN = { top: 0, right: 0, bottom: 30, left: 0 };
const DEFAULT_DATA_WIDTH = 100;
const DEFAULT_HEIGHT = 400;
const DEFAULT_MINIMAP_HEIGHT = 50;
const DEFAULT_MINIMAP_WIDTH = 300;
const DEFAULT_WIDTH = 600;
const MINIMAP_ANNOTATION_HEIGHT = 3;
const MINIMAP_ANNOTATION_PADDING = 2;
const MINIMAP_BACKGROUND_HEIGHT = 5;
const MINIMAP_MARGIN = { top: 1, right: 3, bottom: 0, left: 3 };

const Chart = styled.div`
  background: ${(props) => props.theme.color.grayTT};
  height: 300px;
  position: relative;
  z-index: 20;
`;

const ChartContent = styled.div`
  bottom: 40px;
  box-sizing: border-box;
  left: 0;
  overflow-x: scroll;
  padding-top: 85px;
  position: absolute;
  right: 0;
  top: 0;
`;

const ChartContentText = styled.div`
  font-family: "Source Code Pro", monospace;
  font-size: 70px;
  line-height: 100px; // tie into characterHeight
  letter-spacing: 20px; // tie into characterSpacing
  transform-origin: left center;
  white-space: nowrap;
`;

const ChartSvg = styled.svg`
  display: block;
  position: relative;
  pointer-events: none;
  z-index: 10;
  rect {
    cursor: pointer;
    fill: ${(props) => props.theme.color.mint};
    fill-opacity: 0.33;
    pointer-events: all;
    rx: 2;
    transition: fill-opacity 200ms ease;
    &:first-child,
    &:hover,
    &:focus {
      fill-opacity: 1;
    }
  }
  .chart-annotation--below {
    rect {
      fill: ${(props) => props.theme.color.blueTT};
    }
  }
  .domain {
    opacity: 0;
  }
  .tick {
    line {
      stroke: ${(props) => props.theme.color.gray};
      stroke-width: 3;
    }
    text {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

const Minimap = styled.div`
  background: ${(props) => props.theme.color.blueS};
  height: 4rem;
  margin: -4rem auto 0;
  max-width: calc(75rem - 2rem - 2rem);
  width: 100%;
  position: relative;
  z-index: 10;
`;

const MinimapSvg = styled.svg`
  display: block;
  .minimap-annotation {
    fill: ${(props) => props.theme.color.mint};
    rx: 1;
  }
  .minimap-annotation--below {
    fill: ${(props) => props.theme.color.blueTT};
  }
  .minimap-background {
    fill: ${(props) => props.theme.color.gray};
  }
  .minimap-brush {
    .handle {
      fill: white;
    }
    .selection {
      fill: white;
      fill-opacity: 0.25;
    }
  }
`;

const Spacer = styled.span`
  pointer-events: none;
  user-select: none;
`;

const trackTextSelection = (
  reference: RefObject<HTMLElement>,
  setSelection: (selection: Selection | null) => void
): (() => void) | undefined => {
  if (!reference.current) {
    return;
  }

  const selectionChangeHandler = (_event: Event) => {
    if (!reference.current) {
      return;
    }

    const selection = getCurrentSelection(reference.current);

    setSelection(selection);
  };

  const selectStartHandler = (_event: Event) => {
    document.addEventListener("selectionchange", selectionChangeHandler);
  };
  const element = reference.current;

  element.addEventListener("selectstart", selectStartHandler);
  return () => {
    document.removeEventListener("selectionchange", selectionChangeHandler);
    element.removeEventListener("selectstart", selectStartHandler);
  };
};

const clearTextSelection = (): void => {
  if (window.getSelection) {
    const selection = window.getSelection();

    if (typeof selection?.empty === "function") {
      selection.empty();
    } else if (typeof selection?.removeAllRanges === "function") {
      selection.removeAllRanges();
    }
  }
};

interface BiologicalSequenceSignature {
  addAnnotation: (annotation: NewManualAnnotation) => void;
  annotations: Annotation[];
  annotationTypes: Project["annotationTypes"];
  changeDescription: (annotation: Annotation, description: string) => void;
  changeWeight: (annotation: Annotation, weight: number) => void;
  data: string;
  explainedAnnotation?: SuggestedSequenceAnnotation;
  explainedAnnotationLabel?: string;
  onSelectAnnotation: (annotation: Annotation) => void;
  removeAnnotation: (annotation: Annotation) => void;
}

export const BiologicalSequence: FC<BiologicalSequenceSignature> = ({
  addAnnotation,
  annotations,
  annotationTypes,
  changeDescription,
  changeWeight,
  data,
  explainedAnnotation,
  explainedAnnotationLabel,
  onSelectAnnotation: highlightAnnotation,
  removeAnnotation,
}) => {
  const intl = useIntl();

  const axisRef = useRef<SVGGElement>(null);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<SVGGElement>(null);
  const minimapBrushRef = useRef<SVGGElement>(null);
  const minimapRef = useRef<HTMLDivElement>(null);
  const textContentRef = useRef<HTMLDivElement>(null);
  const textWrapRef = useRef<HTMLDivElement>(null);
  const zoomRef = useRef<SVGRectElement>(null);

  const [brushExtent, setBrushExtent] = useState<Coordinate>();
  const [selection, setSelection] = useState<Selection | null>(null);
  const [selectionRange, setSelectionRange] = useState<Range>();
  const [description, setDescription] = useState(annotationTypes[0]);
  const [weight, setWeight] = useState(3);
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<Annotation | null>(null);
  const [selectedAnnotationRange, setSelectedAnnotationRange] =
    useState<SVGGElement>();

  const manualAnnotationLayers = useMemo(
    () => layerAnnotations(annotations.filter(isManualAnnotation)),
    [annotations]
  );
  const suggestedAnnotationLayers = useMemo(
    () => layerAnnotations(annotations.filter(isSuggestedAnnotation)),
    [annotations]
  );

  const height = chartContainerRef.current?.clientHeight ?? DEFAULT_HEIGHT;
  const minimapHeight =
    minimapRef.current?.clientHeight ?? DEFAULT_MINIMAP_HEIGHT;
  const minimapWidth = minimapRef.current?.clientWidth ?? DEFAULT_MINIMAP_WIDTH;
  const textWidth = textContentRef.current?.scrollWidth ?? DEFAULT_DATA_WIDTH;
  const width = chartContainerRef.current?.clientWidth ?? DEFAULT_WIDTH;

  const chartHeight = useMemo(
    () => height - CHART_MARGIN.top - CHART_MARGIN.bottom,
    [height]
  );
  const chartWidth = useMemo(
    () => width - CHART_MARGIN.left - CHART_MARGIN.right,
    [width]
  );

  const dataLength = useMemo(() => {
    // assuming a spacer on either side.
    return data.length + 2;
  }, [data.length]);

  const characterWidth = useMemo(
    () => textWidth / dataLength,
    [dataLength, textWidth]
  );

  let brushX0 = 0;
  let brushX1 = 0;

  // Axis
  const chartX = useMemo(
    () => scaleLinear().domain([0, dataLength]).range([0, textWidth]),
    [dataLength, textWidth]
  );
  const axisX = useMemo(
    () => scaleLinear().range([0, chartWidth]),
    [chartWidth]
  );
  const axis = useMemo(
    () =>
      axisTop(axisX)
        .ticks(10)
        .tickFormat((numberValue) => {
          const value = numberValue.valueOf();

          if (Math.floor(value) !== value || value % 10) {
            return "";
          }
          return numberValue.toString();
        })
        .tickPadding(10)
        .tickSize(10),
    [axisX]
  );

  useEffect(() => {
    axisRef.current && axis(select(axisRef.current));
  }, [axis]);

  // Minimap
  const minimapChartHeight = useMemo(
    () => minimapHeight - MINIMAP_MARGIN.top - MINIMAP_MARGIN.bottom,
    [minimapHeight]
  );
  const minimapChartWidth = useMemo(
    () => minimapWidth - MINIMAP_MARGIN.left - MINIMAP_MARGIN.right,
    [minimapWidth]
  );

  const minimapX = useMemo(
    () => scaleLinear().domain([0, dataLength]).range([0, minimapChartWidth]),
    [dataLength, minimapChartWidth]
  );
  const minimapBrush = useMemo(
    () =>
      brushX().extent([
        [0, 0],
        [minimapChartWidth, minimapChartHeight],
      ]),
    [minimapChartHeight, minimapChartWidth]
  );

  function brush(event: D3BrushEvent<unknown>) {
    if (
      isUndefinedOrNull(minimapBrushRef.current) ||
      isUndefinedOrNull(axisRef.current) ||
      isUndefinedOrNull(zoomRef.current)
    ) {
      return;
    }

    const axisSelection = select(axisRef.current);
    const minimapBrushSelection = select(minimapBrushRef.current);
    const zoomSelection = select(zoomRef.current);

    if (event.selection) {
      const selection = event.selection || minimapX.range();

      if (isCoordinatePair(selection)) {
        throw new Error("2-dimensional selection unexpectedly received");
      }

      const [x0, x1] = selection;

      if (x1 - x0 < BRUSH_MIN) {
        minimapBrushSelection.attr("opacity", 0);
        minimapBrush.move(minimapBrushSelection, [brushX0, brushX1]);
        return;
      }

      brushX0 = x0;
      brushX1 = x1;

      const domainStart =
        (x0 / characterWidth) * (textWidth / minimapChartWidth);
      const domainEnd = (x1 / characterWidth) * (textWidth / minimapChartWidth);
      axisX.domain([domainStart, domainEnd]);
      axisSelection.attr(
        "transform",
        `translate(${
          ((characterWidth - CHARACTER_SPACING) / 2) *
          zoomTransform(zoomRef.current).k
        }, ${chartHeight + CHART_MARGIN.bottom})`
      );
      axis(axisSelection);

      if (event.mode) {
        minimapBrushSelection.attr("opacity", 1);
        const chartScale =
          (minimapChartWidth / (x1 - x0)) * (chartWidth / textWidth);
        const chartX = -x0 * (textWidth / minimapChartWidth);
        const chartY = (chartScale * chartHeight - chartHeight) / -2;
        zoom.transform(
          zoomSelection,
          zoomIdentity.scale(chartScale).translate(chartX, chartY)
        );
      }
    }

    if (event.type === "end" && !event.selection) {
      if (event.sourceEvent) {
        zoom.scaleTo(zoomSelection, 1);
        zoom.translateTo(
          zoomSelection,
          (event.sourceEvent.layerX * textWidth) / minimapChartWidth,
          0
        );
      }
    }
  }

  useEffect(() => {
    if (!minimapBrushRef.current) {
      return;
    }

    const minimapBrushSelection = select(minimapBrushRef.current);
    minimapBrush(minimapBrushSelection);

    // First brush render, set initial size.
    if (!brushExtent) {
      const initialExtent: Coordinate = [
        0,
        minimapChartWidth * (chartWidth / textWidth),
      ];
      minimapBrush.move(minimapBrushSelection, initialExtent);
      setBrushExtent(initialExtent);
    }
  }, [brushExtent, chartWidth, minimapBrush, minimapChartWidth, textWidth]);

  const zoom = useMemo(
    () =>
      d3zoom<SVGRectElement, unknown>()
        .extent([
          [0, 0],
          [chartWidth, chartHeight],
        ])
        .scaleExtent([chartWidth / textWidth, 1.5])
        .translateExtent([
          [0, 0],
          [textWidth, chartHeight],
        ]),
    [chartHeight, chartWidth, textWidth]
  );

  function transform(event: D3ZoomEvent<SVGRectElement, unknown>) {
    assert(!isUndefinedOrNull(chartRef.current));
    assert(!isUndefinedOrNull(minimapBrushRef.current));
    assert(!isUndefinedOrNull(textContentRef.current));
    assert(!isUndefinedOrNull(textWrapRef.current));

    const chartSelection = select(chartRef.current);
    const minimapBrushSelection = select(minimapBrushRef.current);
    const textContentSelection = select(textContentRef.current);

    if (!event.transform) return;

    const chartScale = event.transform.k;
    const chartX = event.transform.x || 0;
    // @ts-expect-error
    event.transform.x = chartX;
    const chartY = (chartScale * chartHeight - chartHeight) / -2;

    chartSelection.attr(
      "transform",
      `translate(${chartX}, ${chartY}) scale(${chartScale})`
    );
    textContentSelection.attr("style", `transform: scale(${chartScale})`);

    textWrapRef.current.scrollLeft = Math.abs(chartX);

    const minimapCoords = axisX
      .range()
      .map(event.transform.invertX, event.transform);
    minimapCoords[0] *= minimapChartWidth / textWidth;
    minimapCoords[1] *= minimapChartWidth / textWidth;
    minimapBrush.move(minimapBrushSelection, minimapCoords as any);
  }

  minimapBrush.on("brush end", brush);
  zoom.on("zoom", transform);

  useEffect(() => {
    zoomRef.current && zoom(select(zoomRef.current));
  }, [zoom]);

  const onDataScroll: UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!(event.target instanceof HTMLDivElement)) {
        return;
      }

      assert(!isUndefinedOrNull(zoomRef.current));

      const chartScale = zoomTransform(zoomRef.current).k;
      const zoomSelection = select(zoomRef.current);
      zoom.translateTo(
        zoomSelection,
        (event.target.scrollLeft + chartWidth / 2) / chartScale,
        0
      );
    },
    [chartWidth, zoom]
  );

  useEffect(() => {
    return trackTextSelection(textContentRef, (selection) => {
      setSelection(selection);
      setSelectionRange(selection?.getRangeAt(0));
      setSelectedAnnotation(null);
    });
  }, [setSelection, setSelectedAnnotation, textContentRef]);

  const resetState = () => {
    clearTextSelection();
    setSelection(null);
    setSelectionRange(undefined);
    setSelectedAnnotation(null);
    setSelectedAnnotationRange(undefined);
  };

  useEffect(() => {
    // Clear outstanding selection when annotations are saved.
    resetState();
  }, [data]);

  useEffect(() => {
    // Clear outstanding selection when annotations are explained.
    resetState();
  }, [explainedAnnotation]);

  const onAnnotationUpdate = (annotation: Annotation) => {
    if (description) {
      changeDescription(annotation, description);
    }

    if (weight) {
      changeWeight(annotation, weight);
    }

    resetState();
  };

  const onSelectAnnotation = (
    annotation: Annotation,
    rangeRef?: SVGGElement
  ) => {
    setWeight(annotation.weight);
    setDescription(annotation.description);
    setSelectedAnnotationRange(rangeRef);
    setSelectedAnnotation(annotation);
  };

  const onHighlightAnnotation = () => {
    if (!selectedAnnotation) {
      return;
    }

    highlightAnnotation(selectedAnnotation);
  };

  const onRemoveAnnotation = () => {
    if (!selectedAnnotation) {
      return;
    }

    removeAnnotation(selectedAnnotation);
    resetState();
  };

  const onManualAnnotationCreate = (coordinate: Coordinate) => {
    addAnnotation({
      description,
      end: { x: coordinate[1], y: 0 },
      source: "manual",
      start: { x: coordinate[0], y: 0 },
      weight,
    });

    resetState();
  };

  let selectionEndOffset: number | undefined;
  let selectionStartOffset: number | undefined;

  if (textContentRef.current && selection) {
    selectionEndOffset = getSelectionEndOffset(
      textContentRef.current,
      selection
    );
    selectionStartOffset =
      getSelectionStartOffset(textContentRef.current, selection) + 1;
  }

  return (
    <>
      <Minimap ref={minimapRef}>
        <MinimapSvg
          className="minimapSvg"
          height={minimapHeight}
          width={minimapWidth}
        >
          <g
            className="minimap"
            transform={`translate(${MINIMAP_MARGIN.left}, ${MINIMAP_MARGIN.top})`}
          >
            <rect
              className="minimap-background"
              height={MINIMAP_BACKGROUND_HEIGHT}
              width={minimapX(dataLength)}
              y={minimapChartHeight / 2 - MINIMAP_BACKGROUND_HEIGHT / 2}
            />
            {manualAnnotationLayers.map(({ annotation, layer }) => (
              <MinimapAnnotation
                annotation={annotation}
                key={annotation.id}
                layer={layer}
                location={"above"}
                minimapAnnotationHeight={MINIMAP_ANNOTATION_HEIGHT}
                minimapAnnotationPadding={MINIMAP_ANNOTATION_PADDING}
                minimapBackgroundHeight={MINIMAP_BACKGROUND_HEIGHT}
                minimapChartHeight={minimapChartHeight}
                minimapX={minimapX}
              />
            ))}
            {suggestedAnnotationLayers.map(({ annotation, layer }) => (
              <MinimapAnnotation
                annotation={annotation}
                key={annotation.id}
                layer={layer}
                location={"below"}
                minimapAnnotationHeight={MINIMAP_ANNOTATION_HEIGHT}
                minimapAnnotationPadding={MINIMAP_ANNOTATION_PADDING}
                minimapBackgroundHeight={MINIMAP_BACKGROUND_HEIGHT}
                minimapChartHeight={minimapChartHeight}
                minimapX={minimapX}
              />
            ))}
            <g className="minimap-brush" ref={minimapBrushRef}></g>
          </g>
        </MinimapSvg>
      </Minimap>
      <Chart ref={chartContainerRef}>
        <ChartContent onScroll={onDataScroll} ref={textWrapRef}>
          <ChartContentText ref={textContentRef}>
            <Spacer>&nbsp;</Spacer>
            <WeightedSequenceData
              data={data}
              label={explainedAnnotationLabel}
              ranges={explainedAnnotation?.data_repr}
            />
            <Spacer>&nbsp;</Spacer>
          </ChartContentText>
        </ChartContent>
        <ChartSvg height={height} width={width}>
          <defs>
            <symbol
              id="annotation-above"
              viewBox="0 0 16 16"
              height="16"
              width="16"
            >
              <path
                d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                transform="translate(4, 4) scale(0.75)"
              />
            </symbol>
            <symbol
              id="annotation-below"
              viewBox="0 0 16 16"
              height="16"
              width="16"
            >
              <g transform="translate(4, 4) scale(0.75)">
                <path d="M14.9558 7.41731C14.6237 7.24498 14.2902 7.09164 13.9552 6.95728C13.3049 6.69478 12.636 6.48098 11.9541 6.31763C11.3093 6.16218 10.6556 6.04659 9.99672 5.97152C9.92167 5.31227 9.8064 4.65825 9.65156 4.01314C9.48818 3.32953 9.27498 2.65887 9.01365 2.00657C8.87966 1.6736 8.73402 1.33917 8.57673 1.00329C8.40281 0.65415 8.20271 0.318757 7.97815 0C7.75359 0.318757 7.55349 0.65415 7.37957 1.00329C7.20772 1.33625 7.06208 1.67068 6.94265 2.00657C6.68133 2.65887 6.46812 3.32953 6.30475 4.01314C6.14975 4.6597 6.03448 5.31518 5.95958 5.9759C5.30067 6.05101 4.64697 6.16659 4.00218 6.32202C3.32044 6.48584 2.65161 6.69963 2.00109 6.96166C1.66903 7.09602 1.33552 7.24206 1.00055 7.39978C0.652363 7.57418 0.317887 7.77483 0 8C0.317887 8.22517 0.652363 8.42582 1.00055 8.60022C1.3326 8.77254 1.66612 8.91858 2.00109 9.03833C2.65161 9.30037 3.32044 9.51416 4.00218 9.67798C4.64554 9.83323 5.29777 9.94882 5.95522 10.0241C6.03009 10.6848 6.14536 11.3403 6.30038 11.9869C6.4633 12.6706 6.67651 13.3413 6.93828 13.9934C7.07227 14.3264 7.2252 14.6608 7.39705 14.9967C7.57219 15.3461 7.77377 15.6815 8 16C8.22625 15.6815 8.42782 15.3461 8.60295 14.9967C8.7748 14.6637 8.92773 14.3293 9.06171 13.9934C9.3235 13.3413 9.53672 12.6706 9.69962 11.9869C9.85447 11.3417 9.96974 10.6877 10.0448 10.0285C10.7022 9.95323 11.3545 9.83764 11.9978 9.68237C12.6797 9.51901 13.3485 9.3052 13.9989 9.04272C14.331 8.90836 14.6645 8.75502 14.9995 8.58269C15.3479 8.40707 15.6824 8.20495 16 7.97809C15.6669 7.76434 15.3178 7.57684 14.9558 7.41731V7.41731Z" />
                <ellipse cx="14.455" cy="1.45496" rx="1.45495" ry="1.45496" />
              </g>
            </symbol>
          </defs>

          <rect className="zoom" ref={zoomRef}></rect>
          <g
            className="axis"
            transform={`translate(${CHART_MARGIN.left}, ${
              chartHeight + CHART_MARGIN.bottom
            })`}
            ref={axisRef}
          ></g>
          <g className="chart" ref={chartRef}>
            {manualAnnotationLayers.map(({ annotation, layer }) => (
              <LayeredAnnotationRange
                annotation={annotation}
                characterHeight={CHARACTER_HEIGHT}
                characterSpacing={CHARACTER_SPACING}
                chartAnnotationHeight={CHART_ANNOTATION_HEIGHT}
                chartAnnotationPadding={CHART_ANNOTATION_PADDING}
                chartHeight={chartHeight}
                chartX={chartX}
                key={annotation.id}
                label={intl.formatMessage(
                  { id: "Visualization of {name} at {location}" },
                  {
                    location: humanizeCoordinate("sequence", annotation.start),
                    name: annotation.description,
                  }
                )}
                layer={layer}
                location={"above"}
                onSelect={(rangeRef) =>
                  onSelectAnnotation(annotation, rangeRef)
                }
              />
            ))}
            {suggestedAnnotationLayers.map(({ annotation, layer }) => (
              <LayeredAnnotationRange
                annotation={annotation}
                characterHeight={CHARACTER_HEIGHT}
                characterSpacing={CHARACTER_SPACING}
                chartAnnotationHeight={CHART_ANNOTATION_HEIGHT}
                chartAnnotationPadding={CHART_ANNOTATION_PADDING}
                chartHeight={chartHeight}
                chartX={chartX}
                key={annotation.id}
                label={intl.formatMessage(
                  { id: "Visualization of {name} at {location}" },
                  {
                    location: humanizeCoordinate("sequence", annotation.start),
                    name: annotation.description,
                  }
                )}
                layer={layer}
                location={"below"}
                onSelect={(rangeRef) =>
                  onSelectAnnotation(annotation, rangeRef)
                }
              />
            ))}
          </g>
        </ChartSvg>
      </Chart>
      {!selectedAnnotation &&
        selection &&
        selectionEndOffset &&
        selectionStartOffset && (
          <ManualAnnotation
            annotationTypes={annotationTypes}
            description={description}
            end={[selectionEndOffset, 0]}
            onSubmit={() => {
              if (
                isUndefined(selectionStartOffset) ||
                isUndefined(selectionEndOffset)
              ) {
                return;
              }

              onManualAnnotationCreate([
                selectionStartOffset,
                selectionEndOffset,
              ]);
            }}
            reference={selectionRange}
            setDescription={setDescription}
            setWeight={setWeight}
            start={[selectionStartOffset, 0]}
            weight={weight}
          />
        )}
      {selectedAnnotation && (
        <UpdateAnnotation
          annotationTypes={annotationTypes}
          deleteAnnotation={() => onRemoveAnnotation()}
          description={description}
          end={[selectedAnnotation.end.x, selectedAnnotation.end.y]}
          highlightAnnotation={() => onHighlightAnnotation()}
          onSubmit={() => {
            onAnnotationUpdate(selectedAnnotation);
          }}
          reference={selectedAnnotationRange}
          setDescription={setDescription}
          setWeight={setWeight}
          start={[selectedAnnotation.start.x, selectedAnnotation.start.y]}
          weight={weight}
        />
      )}
      <Form
        annotationTypes={annotationTypes}
        defaultEndX={selectionEndOffset}
        defaultStartX={selectionStartOffset}
        description={description}
        minX={1}
        maxX={data.length}
        onSubmit={(coordinate) => {
          onManualAnnotationCreate(coordinate);
        }}
        setDescription={setDescription}
        setWeight={setWeight}
        weight={weight}
      />
    </>
  );
};
