import { FC } from "react";
import styled from "styled-components";

const Rect = styled.rect`
  cursor: pointer;
  fill: ${(props) => props.theme.color.blue};
  fill-opacity: 0.2;
  stroke: ${(props) => props.theme.color.blue};
  stroke-opacity: 0.5;
  stroke-width: 2;
  transition: fill-opacity 200ms ease;
  &:hover,
  &:focus {
    fill-opacity: 0.4;
  }
`;

interface VisualizedAnnotationSignature {
  height: number;
  label: string;
  onSelect: () => void;
  width: number;
  x: number;
  y: number;
}

export const VisualizedAnnotation: FC<VisualizedAnnotationSignature> = ({
  height,
  label,
  onSelect,
  width,
  x,
  y,
}) => {
  return (
    <Rect
      aria-label={label}
      height={height}
      onClick={() => {
        onSelect();
      }}
      width={width}
      x={x}
      y={y}
    />
  );
};
