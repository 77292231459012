import { Project } from "../app/models/project";
import { Annotation } from "../app/redux/features/annotations";
import { humanizeCoordinate } from "../utils/humanize-coordinate";
import Form from "./Form";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

export type DescriptionChangeHandler = (
  annotation: Annotation,
  description: string
) => void;

interface DescriptionSignature {
  annotation: Annotation;
  descriptions: string[];
  onChange?: DescriptionChangeHandler;
  projectType: Project["type"];
}

export const Description: FC<DescriptionSignature> = ({
  annotation,
  descriptions,
  onChange,
  projectType,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label>
        <span className="srt">
          <FormattedMessage
            id="Description of {name} at {location}"
            values={{
              location: humanizeCoordinate(projectType, annotation.start),
              name: annotation.description,
            }}
          />
        </span>
        <Form.SelectWrap>
          <Form.Select
            value={annotation.description}
            onChange={(e) => {
              onChange?.(annotation, e.target.selectedOptions[0].value);
            }}
          >
            {descriptions.map((description) => (
              <option key={description}>{description}</option>
            ))}
          </Form.Select>
        </Form.SelectWrap>
      </label>
    </form>
  );
};
