import { Annotation } from "../../../app/redux/features/annotations";
import { ScaleLinear } from "d3";
import { FC, useRef } from "react";

interface LayeredAnnotationRangeSignature {
  annotation: Annotation;
  characterHeight: number;
  characterSpacing: number;
  chartAnnotationHeight: number;
  chartAnnotationPadding: number;
  chartHeight: number;
  chartX: ScaleLinear<number, number, never>;
  label: string;
  layer: number;
  location: "above" | "below";
  onSelect: (rangeRef?: SVGGElement) => void;
}

export const LayeredAnnotationRange: FC<LayeredAnnotationRangeSignature> = ({
  annotation,
  characterHeight,
  characterSpacing,
  chartAnnotationHeight,
  chartAnnotationPadding,
  chartHeight,
  chartX,
  label,
  layer,
  location,
  onSelect,
}) => {
  const rangeRef = useRef<SVGGElement>(null);
  const modifier = location === "below" ? 1 : -1;
  const x = chartX(annotation.start.x);
  const width = chartX(annotation.end.x + 1) - x - characterSpacing;
  const y =
    chartHeight / 2 -
    chartAnnotationPadding -
    (-characterHeight / 2 -
      (chartAnnotationHeight + chartAnnotationPadding) * (layer - 1)) *
      modifier;

  return (
    <g
      className={`chart-annotation chart-annotation--${location}`}
      data-annotation={annotation.id}
      onClick={() => {
        onSelect(rangeRef.current ? rangeRef.current : undefined);
      }}
      ref={rangeRef}
      transform={`translate(${x}, ${y})`}
    >
      <rect height={chartAnnotationHeight} width={chartAnnotationHeight} />
      <rect aria-label={label} height={chartAnnotationHeight} width={width} />
      {location === "above" && <use href="#annotation-above" />}
      {location === "below" && <use href="#annotation-below" />}
    </g>
  );
};
