import { Project } from "../app/models/project";
import {
  SuggestedAnnotation,
  modifySuggestedReasoningWeight,
} from "../app/redux/features/annotations";
import { useTypedDispatch } from "../app/redux/store";
import {
  SuggestedReasoningWeight,
  SuggestedReasoningWeightChangeHandler,
} from "./SuggestedReasoningWeight";
import { FC } from "react";
import styled from "styled-components";

const LI = styled.li`
  align-items: center;
  display: flex;
  gap: 1rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

interface SuggestedReasoningRowSignature {
  annotation: SuggestedAnnotation;
  project: Project;
  suggestedReasoning: SuggestedAnnotation["suggested_reasoning"][0];
}

export const SuggestedReasoningRow: FC<SuggestedReasoningRowSignature> = ({
  annotation,
  project,
  suggestedReasoning,
}) => {
  const dispatch = useTypedDispatch();

  const onSuggestedReasoningWeightChange: SuggestedReasoningWeightChangeHandler =
    (annotation, suggestedReasoning, weight) => {
      dispatch(
        modifySuggestedReasoningWeight({
          annotationId: annotation.id,
          project: project.toData(),
          suggestionId: suggestedReasoning.id,
          weight,
        })
      );
    };

  return (
    <LI>
      <SuggestedReasoningWeight
        annotation={annotation}
        onChange={onSuggestedReasoningWeightChange}
        suggestedReasoning={suggestedReasoning}
      />
      {suggestedReasoning.description}
    </LI>
  );
};
