import { key } from "./slice";
import { State } from "./types";
import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state: { [key]: State }) => state[key];

export const selectAllAnnotations = createSelector(
  selectSelf,
  (state) => state.annotations
);
export const selectIsLocallyModified = createSelector(
  selectSelf,
  (state) => state.isLocallyModified
);
export const selectById = createSelector(
  [selectSelf, (_state, id: string | undefined) => id],
  (state, id: string | undefined) => state.annotations.find((a) => a.id === id)
);
