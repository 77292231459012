import { FC } from "react";
import styled, { keyframes } from "styled-components";

const loopContainerPath = keyframes`
  to {
    opacity: 0;
  }
`;

const Container = styled.div`
  display: inline-block;
  flex-shrink: 0;
  height: 1em;
  pointer-events: none;
  vertical-align: -0.1em;
  width: 1em;
  svg {
    display: block;
    height: auto;
    max-width: 100%;
    *:nth-child(1) {
      animation: ${loopContainerPath} 1000ms ease infinite alternate -750ms;
    }
    *:nth-child(2) {
      animation: ${loopContainerPath} 1000ms ease infinite alternate -500ms;
    }
    *:nth-child(3) {
      animation: ${loopContainerPath} 1000ms ease infinite alternate -250ms;
    }
    *:nth-child(4) {
      animation: ${loopContainerPath} 1000ms ease infinite alternate;
    }
  }
`;

const Spinner: FC = () => {
  return (
    <Container>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6667 21.3333C4.77564 21.3333 -2.08749e-07 16.5577 -4.66255e-07 10.6666V10.6666C-7.2376e-07 4.77561 4.77563 -2.07127e-05 10.6667 -2.09703e-05L32 -2.19028e-05C37.891 -2.21603e-05 42.6667 4.77561 42.6667 10.6666V10.6666C42.6667 16.5577 37.891 21.3333 32 21.3333L10.6667 21.3333Z"
          fill="#66DDC0"
        />
        <circle cx="53.3334" cy="10.6667" r="10.6667" fill="#0052A4" />
        <path
          d="M42.6665 42.6666L21.3332 42.6666L21.3332 32C21.3332 26.1089 26.1088 21.3333 31.9998 21.3333V21.3333C37.8909 21.3333 42.6665 26.1089 42.6665 32L42.6665 42.6666Z"
          fill="#DDE2E9"
        />
        <path
          d="M21.3333 42.6666V53.3333C21.3333 59.2244 26.1089 64 31.9999 64C37.891 64 42.6666 59.2244 42.6666 53.3333V42.6666L21.3333 42.6666L21.3333 42.6666L21.3333 42.6666Z"
          fill="#143162"
        />
      </svg>
    </Container>
  );
};

export default Spinner;
