import { store } from "../app/redux/store";
import { AuthenticationProvider } from "../contexts/auth";
import { scenarios } from "../mocks/handlers/tympana/scenarios";
import { store as tympanaStore } from "../mocks/handlers/tympana/store";
import theme from "../styles/theme";
import en from "../translations/en.json";
import { isDevelopment } from "../utils/environment";
import { FC, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

interface AppProvidersSignature {
  children?: ReactNode;
}

if (isDevelopment) {
  scenarios.default(tympanaStore);
}

const AppProviders: FC<AppProvidersSignature> = ({ children }) => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <IntlProvider locale="en" messages={en}>
          <ThemeProvider theme={theme}>
            <AuthenticationProvider>{children}</AuthenticationProvider>
          </ThemeProvider>
        </IntlProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default AppProviders;
