import { Project } from "../../app/models/project";
import { isUndefinedOrNull } from "../../utils/assertions";
import Form from "../Form";
import {
  ComputePositionReturn,
  computePosition,
  flip,
  offset,
  shift,
} from "@floating-ui/react-dom-interactions";
import { FC, FormEventHandler, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled, { keyframes } from "styled-components";

const Field = styled.label`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  > *:first-child {
    flex-shrink: 0;
    width: 5rem;
  }
`;

const enterTooltip = keyframes`
  from {
    opacity: 0;
    transform: translateY(0.25rem);
  }
`;

const Tooltip = styled.div`
  animation: ${enterTooltip} 500ms ${(props) => props.theme.ease.quintOut};
  background: ${(props) => props.theme.color.white};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  font-size: ${(props) => props.theme.size.down1};
  padding: 1rem;
  pointer-events: all;
  position: absolute;
  text-align: left;
  z-index: 30;
`;

const TooltipButton = styled.button`
  background: ${(props) => props.theme.color.blueT};
  box-shadow: inset 0 0 0 2px ${(props) => props.theme.color.blueT};
  color: ${(props) => props.theme.color.white};
  display: block;
  font-size: ${(props) => props.theme.size.down2};
  font-weight: bold;
  padding: 0.25rem 1rem;
  text-align: center;
  width: 100%;
  &:hover,
  &:focus {
    background: transparent;
    color: ${(props) => props.theme.color.blueT};
  }
`;

interface ManualAnnotationSignature {
  annotationTypes: Project["annotationTypes"];
  description: string;
  end: [number, number];
  onSubmit?: () => void;
  reference?: Element | Range;
  setDescription: (description: string) => void;
  setWeight: (weight: number) => void;
  start: [number, number];
  weight: number;
}

export const ManualAnnotation: FC<ManualAnnotationSignature> = ({
  annotationTypes,
  description,
  end,
  onSubmit,
  reference: brushRef,
  setDescription,
  setWeight,
  start,
  weight,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<ComputePositionReturn | undefined>();

  useEffect(() => {
    if (isUndefinedOrNull(tooltipRef.current) || isUndefinedOrNull(brushRef)) {
      return;
    }

    computePosition(brushRef, tooltipRef.current, {
      placement: "top",
      middleware: [flip(), offset(15), shift({ padding: 5 })],
    }).then(setPosition);
  }, [brushRef, end, start]);

  const submitAnnotation: FormEventHandler = (event) => {
    event.preventDefault();
    onSubmit?.();
  };

  return (
    <Tooltip
      ref={tooltipRef}
      role="tooltip"
      style={{
        left: position?.x ?? 0,
        position: position?.strategy ?? "absolute",
        top: position?.y ?? 0,
      }}
    >
      <form onSubmit={submitAnnotation}>
        <Field>
          <Form.Label>
            <FormattedMessage id="Description" />:
          </Form.Label>
          <Form.SelectWrap>
            <Form.Select
              required={true}
              onChange={(e) => {
                setDescription(e.target.selectedOptions[0].value);
              }}
              value={description}
            >
              {annotationTypes.map((annotationType) => (
                <option key={annotationType} value={annotationType}>
                  {annotationType}
                </option>
              ))}
            </Form.Select>
          </Form.SelectWrap>
        </Field>
        <Field>
          <Form.Label>
            <FormattedMessage id="Weight" />:
          </Form.Label>
          <Form.RangeWrap>
            <Form.Range
              max={5}
              min={1}
              onChange={(e) => {
                setWeight(parseInt(e.target.value, 10));
              }}
              step={1}
              type={"range"}
              value={weight}
            />
          </Form.RangeWrap>
        </Field>
        <TooltipButton type="submit">Add Annotation</TooltipButton>
      </form>
    </Tooltip>
  );
};
