import { Project } from "../app/models/project";
import Button from "./Button";
import Icon from "./Icon";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

const LinkButton = Button.withComponent(Link);

interface ExportButtonSignature {
  project: Project;
  variant?: "white";
}

export const ExportButton: FC<ExportButtonSignature> = ({
  project,
  variant,
}) => {
  const intl = useIntl();

  return (
    <>
      {project.isExportable && (
        <LinkButton
          aria-label={intl.formatMessage(
            { id: "Export {name}" },
            { name: project.name }
          )}
          to={`/projects/${project.id}/export`}
          $fixed={true}
          $variant={variant}
        >
          <Icon id="download" />
            Export
        </LinkButton>
      )}
      {!project.isExportable && (
        <Button
          disabled={true}
          $fixed={true}
          $state="disabled"
          $variant={variant}
        >
          <span>
            <FormattedMessage
              id="Export Estimate: {count} Instances"
              values={{
                break: () => <br />,
                count: project.confidence.estimatedRemainingSamples,
              }}
            />
          </span>
        </Button>
      )}
    </>
  );
};
