import { FC } from "react";
import { FormattedRelativeTime, useIntl } from "react-intl";

interface RelativeTimeSignature {
  date: Date;
}

const RelativeTime: FC<RelativeTimeSignature> = ({ date }) => {
  const intl = useIntl();

  return (
    <time
      dateTime={date.toISOString()}
      title={intl.formatDate(date, {
        dateStyle: "full",
        timeStyle: "long",
      })}
    >
      <FormattedRelativeTime
        updateIntervalInSeconds={60}
        value={Math.round(
          new Date(date).getTime() / 60000 - new Date().getTime() / 60000
        )}
      />
    </time>
  );
};

export default RelativeTime;
