export type Coordinate = [number, number];
export type CoordinatePair = [Coordinate, Coordinate];

export function isCoordinatePair(object: unknown): object is CoordinatePair {
  return (
    Array.isArray(object) &&
    object.length === 2 &&
    Array.isArray(object[0]) &&
    Array.isArray(object[1]) &&
    object[0].length === 2 &&
    object[1].length === 2
  );
}
